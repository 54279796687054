.ai-block-steps {
  @apply bg-[url('../img/bg-pattern.jpg')] lg:pt-[133px] lg:pb-[110px] py-[60px] px-0;

  &.first-block {
    @apply lg:mt-[100px] md:mt-[80px] mt-[60px];
  }

  .top {
    @apply text-center lg:mb-[90px] mb-[50px];
  }

  .title+.desc {
    @apply mt-[20px];
  }

  .steps {
    background: linear-gradient(139.41deg, #0074e8, #00eac3);
    @apply min-[1440px]:py-[34px] min-[1440px]:px-[80px] lg:p-[34px] py-[34px] px-0 text-center shadow-[0px_10px_30px_-10px_rgba(37,1,68,0.2)] rounded-[6px];
  }

  .subtitle {
    @apply font-semibold;
  }

  .subtitle+.flex_box {
    @apply mt-[40px];
  }

  .flex_box {
    @apply lg:flex inline-flex lg:flex-row flex-col lg:p-0 pl-[50px] gap-x-[54px] gap-y-[54px];

    .column {
      @apply lg:block flex lg:text-center text-left gap-x-[15px] relative last-of-type:before:bottom-auto lg:pt-[30px] lg:flex-grow lg:basis-0 first-of-type:desktop:before:left-[50%] last-of-type:desktop:before:right-[50%];

      &:before {
        @apply content-[''] absolute md:top-[30px] desktop:top-0 top-[18px] desktop:bottom-auto md:bottom-[-84px] bottom-[-72px] desktop:left-[-54px] desktop:right-[-54px] desktop:h-[3px] desktop:w-auto w-[3px] bg-sol-green;
      }

      &:after {
        @apply content-[''] absolute lg:top-0 md:top-[30px] top-[18px] lg:left-[50%] left-[-20.5px] h-[14px] w-[14px] bg-sol-green border-[3px] border-solid border-sol-white shadow-[0px_6.6px_19.9px_-6.6px_rgba(37,_1,_68,_0.2)] rounded-[50%] translate-x-[-7px] translate-y-[-7px];        
      }
    }
  }

  .number {
    @apply font-bold;
  }

  .text {
    @apply lg:p-0 pt-[7px] max-w-[220px];
  }

  .ctas {
    @apply lg:mt-[62px] mt-[50px] text-center;
  }
}