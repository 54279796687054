/*
* Pagination
*/

.pagination {
	@apply my-[50px] mx-0 w-full flex justify-center text-center items-center;

	.wp-pagenavi {
		@apply flex items-center gap-[6px];

		.pages,
		.last,
		.first {
			@apply hidden;
		}

		.previouspostslink,
		.nextpostslink {
			@apply bg-[size:_24px_24px] bg-center bg-no-repeat indent-[250%] overflow-hidden hover:opacity-[.3];
		}

		.previouspostslink {
			@apply bg-[url('../img/chevron-left.svg')];
		}

		.nextpostslink {
			@apply bg-[url('../img/chevron-right.svg')];
		}

		a,
		span {
			@apply text-sol-charcoal leading-none text-[16px] w-auto min-w-[24px] h-[24px] inline-flex items-center justify-center border-none p-0 m-0 rounded-[50%] font-medium;

			&.current {
				@apply bg-sol-royal-blue text-sol-white border-none p-[4px];
			}
		}
	}
}