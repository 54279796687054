.columns-with-header-block {
  .block-header .header-cta a {
    @apply block;
  }

  .columns {
    .column-item {
      @apply p-[32px] mb-[32px];

      &.no-padding {
        @apply p-0;
      }

      .icon {
        @apply basis-[10%];
      }

      .item-content {
        @apply basis-full;
      }

      .column-content {
        @apply my-[24px] mx-0;
      }

      .column-footer {
        @apply gap-[15px];
      }
    }

    .shadow-column {
      @apply shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)];
    }

    .rounded {
      @apply rounded-[36px];
    }
  }

  .icon {
    @apply text-[32px];

    i {
      @apply text-[32px];
    }
  }

  .stacked-layout {
    @include media(desktop) {
      @apply flex;

      .block-header {
        @apply basis-full;
      }
    }
  }
}