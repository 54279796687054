.ai-block-assessment-entries {
  &.first-block {
    @apply lg:mt-[100px] md:mt-[80px] mt-[60px];
  }

  .assessment_entries {
    @apply overflow-auto;

    h1 {
      @apply mb-[30px];
    }

    .entries {
      @apply min-w-[600px] border-b-0 border-x-[1px] border-t-[1px] border-solid border-[#ddd];
    }

    .entry {
      @apply flex border-b-[1px] border-x-0 border-t-0 border-solid border-[#ddd] bg-sol-white first-of-type:bg-sol-charcoal first-of-type:font-bold first-of-type:py-[2px] first-of-type:px-0 first-of-type:text-sol-white first-of-type:hover:bg-sol-charcoal;

      &:hover {
        @apply bg-[#eee];
      }

      & > * {
        @apply break-words w-[calc((100%-180px)/3)] border-y-0 border-l-0  border-r-[1px] border-solid border-[#ddd] py-[5px] px-[10px] first-of-type:w-[90px] last-of-type:w-[90px];        
      }

      .buttons {
        @apply flex items-center gap-[10px];

        a {
          @apply leading-[1] shadow-none no-underline text-[0];
        }

        img {
          @apply h-[18px] w-[18px];
        }
      }
    }
  }

  .assessment_pagination {
    @apply text-center mt-[50px];

    a {
      @apply shadow-none no-underline text-[inherit];      
    }

    .page-numbers {
      @apply min-w-[30px] pl-[6px] pr-[6px] h-[30px] inline-flex justify-center items-center mr-[6px] last-of-type:m-0;

      &.current {
        @apply bg-sol-charcoal text-sol-white;
      }
    }
  }
}
