$llm_shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
$llm_radius: 36px;

body.baunfire-homepage.baunfire-homepage--llm {
  h1 {
    @apply md:text-[72px] text-[40px] leading-[1.11] tracking-[-0.02em] #{!important};
  }

  .grow {
    @apply transition-all duration-[.3s] ease-in-out hover:translate-x-[5px] hover:translate-y-[5px];
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    span {
      background: linear-gradient(to top, #52A9F5 100%, transparent 0);
      @apply text-sol-white bg-[position:_top_left] bg-no-repeat bg-[size:_105%_auto] transition-[background-size] duration-[.5s] py-0 px-[10px];
    }
  }

  p {
    @apply md:text-[18px] text-[16px] md:leading-[25px] leading-[22px];

    a {
      @apply font-semibold;
    }
  }
}

.noPaddingTop {
  @apply pt-0 #{!important};
}

.bgImage {
  @apply absolute top-0 right-0 bottom-0 left-0;

  img {
    @apply w-full h-full object-cover object-center;
  }
}
