@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--blue: 0 116 232;
		--navy-blue: 0 64 128;
		--deep-purple: 37 1 68;
		--charcoal: 37 40 46;
		--grey: 243 249 254;
		--white: 255 255 255;
		--purple: 151 45 237;
		--teal: 39 233 195;
		--gold: 253 181 43;
		--black: 23 25 27;
		--color-red: #d70000;
		--color-blue: rgb(var(--blue, 0 116 232));
		--color-navy-blue: rgb(var(--navy-blue, 0 64 128));
		--color-deep-purple: rgb(var(--deep-purple, 37 1 68));
		--color-charcoal: rgb(var(--charcoal, 37 40 46));
		--color-grey: rgb(var(--grey, 243 249 254));
		--color-white: rgb(var(--white, 255 255 255));
		--color-teal: rgb(var(--teal, 39 233 195));
		--color-gold: rgb(var(--gold, 253 181 43));
		--color-black: rgb(var(--black, 23 25 27));
		--color-blue-50: rgba(0, 116, 232, 0.5);
		--color-purple-50: rgba(151, 45, 237, 0.5);
		--color-charcoal-75: rgba(37, 40, 46, 0.75);
		--color-charcoal-50: rgba(37, 40, 46, 0.5);
		--color-charcoal-15: rgba(37, 40, 46, 0.15);
		--color-text-selection: rgba(172, 206, 247, 1);
		--blue-gradient: linear-gradient(90deg, #0074e8 0%, #a933fb 100%);
	}
}

@layer components {
	.row {
		@apply mx-[calc(-.5*theme(spacing.gutter-x))] my-[calc(-1*theme(spacing.gutter-y))] grid grid-cols-12;

		&>* {
			@apply max-w-full px-[calc(theme(spacing.gutter-x)*.5)] mt-gutter-y;			
		}
	}
}