.ai-block-shadow-container {
  @apply bg-[url('../img/bg-pattern.jpg')] relative;

  &:after {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
    @apply content-[''] absolute block bottom-0 left-0 h-[150px] w-full;
  }

  .container .container {    
    @apply p-0;
  }

  .inner {
    @include shadow-inner();
    @apply lg:pt-[83px] pt-[40px] lg:px-[40px] px-[20px] pb-[40px];

    &>.top {
      @apply text-center mb-[26px];

      .title+.desc {
        @apply mt-[20px];
      }
    }
  }
}