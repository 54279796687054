.ai-block-score {
  @apply lg:mb-[90px] mb-[50px] text-[14px];

  .top {
    @apply mb-[30px] text-center;

    .title+.desc {
      @apply lg:mt-[60px] mt-[30px];
    }

    .desc {
      @apply font-bold;
    }
  }

  .flex_box {
    @apply flex lg:flex-row flex-col;

    .percent {
      @apply flex items-center justify-center flex-col py-[40px] px-0 first-of-type:pt-0 last-of-type:pb-0 lg:flex-grow lg:basis-0;

      &:first-of-type,
      &:last-of-type {
        @apply lg:py-[50px] px-0;
      }

      &:not(:last-of-type) {
        @apply lg:border-b-0 border-b lg:border-r border-x-0 border-t-0 border-solid border-sol-charcoal border-opacity-[.1];
      }

      &:nth-of-type(1) .number {
        @apply text-sol-light-purple;              
      }

      &:nth-of-type(2) .number {
        @apply text-sol-royal-blue;        
      }

      &:nth-of-type(3) .number {
        @apply text-sol-orange;        
      }

      .number {
        @apply font-bold mb-[5px];
      }
    }
  }
}