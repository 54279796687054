.llm-divided-content-block {
  @apply desktop:py-[120px] md:py-[100px] py-[80px] px-0 relative;

  .dividedContent {
    @apply flex gap-[60px] items-stretch py-[30px] px-0;
  }

  .dividedContent-titles {
    @apply relative;
  }

  .dividedContent:has(.dividedContent-text),
  .dividedContent:has(.dividedContent-boxes) {
    .dividedContent-titles:after {
      @apply content-[''] absolute top-[-30px] bottom-[-30px] right-[-30px] w-[2px] bg-[#52A9F5] bg-opacity-[.5];
    }
  }

  .dividedContent.dividedContent--text {
    &.dividedContent--widthdefault .dividedContent-titles {        
      @apply w-[50%];      
    }

    &.dividedContent--widthwider .dividedContent-titles {     
      @apply w-[40%];         
    }
  }

  .dividedContent-text {
    @apply pl-[20px] flex-[1]; 
  }

  .dividedContent-image {
    @apply mt-[60px];

    img,
    svg {
      @apply block m-[0_auto] h-auto;
    }
  }

  // BOXES STYLE

  .dividedContent.dividedContent--boxes .dividedContent-titles {      
    @apply flex-[1];
  }

  .dividedContent-boxes {
    @apply w-[60%] flex flex-wrap gap-[46px] pl-[20px];

    .dividedContentBox {
      @apply bg-sol-white shadow-[0px_6px_10px_0px_rgba(0,_0,_0,_0.08)] rounded-[16px] p-[20px_30px];

      &:nth-child(odd) {
        @apply basis-[calc(40%_-_23px)] max-w-[calc(40%_-_23px)];
      }

      &:nth-child(even) {
        @apply basis-[calc(60%_-_23px)] max-w-[calc(60%_-_23px)];
      }
    }

    .dividedContentBox-title {
      @apply text-[16px] font-bold;
    }

    .dividedContentBox-text {      
      @apply mt-[10px];

      p {
        @apply text-[14px] leading-[1.4];
      }
    }
  }

  // REPEATING STYLES

  .dividedContent.dividedContent--repeating {
    .dividedContent-titles {      
      @apply flex-[1];
    }

    .dividedContent-content_items {  
      @apply w-[60%] pl-[20px];

      .dividedContent-contentItem {
        @apply mb-[40px] pb-[40px] border-b-[2px] border-x-0 border-t-0 border-solid border-[#52A9F5] border-opacity-[.5];

        &:last-child {
          @apply mb-0 pb-0 border-b-0;
        }
      }
    }

    .contentItem-title {
      @apply mb-[20px];
    }

    .contentItem-text >*:last-child {
      @apply mb-0;      
    }
  }

  // RESPONSIVE

  @media (max-width: 1023px) {
    .dividedContent {
      @apply flex-wrap;

      >* {
        @apply w-full max-w-full #{!important};
      }
    }

    .dividedContent:has(.dividedContent-text),
    .dividedContent:has(.dividedContent-boxes) {
      .dividedContent-titles:after {
        @apply right-0 left-0 bottom-[-30px] top-auto w-auto h-[2px];
      }
    }

    .dividedContent-text,
    .dividedContent-boxes,
    .dividedContent-content_items {
      @apply pl-0 #{!important};
    }

    .dividedContent-image {
      @apply mt-[40px];
    }
  }

  .dividedContent-boxes .dividedContentBox {
    @media (max-width: 767px) {
      @apply basis-full max-w-full #{!important};
    }
  }
}