/*
 * Tables
 */

table {
	@apply w-full;

	thead {
		@apply bg-sol-charcoal;

		tr {
			@apply border-b-0;

			th {
				@apply text-sol-white text-[20px] leading-[1.2]	font-semibold py-[10px] px-[12px] border-[1px] border-solid border-sol-charcoal tracking-[0.025rem];

				&.has-text-align-left {
					@apply text-left;
				}

				&.has-text-align-center {
					@apply text-center;
				}

				&.has-text-align-right {
					@apply text-right;
				}
			}
		}
	}

	tbody {
		th,
		td {
			@apply bg-[var(--color-grey)] border-[rgb(var(--charcoal)/0.15)] text-sol-charcoal border-[1px] border-solid text-[24px] font-normal leading-[1.4] py-[10px] px-[12px] text-left align-middle;

			@include media(mobile) {
				@apply py-[13px] px-[15px];
			}
		}
	}
}

figure.wp-block-table {
	@apply mt-[32px];

	figcaption.wp-element-caption {
		@apply mt-[24px] leading-[1.4] text-[16px];
	}
	table {
		td,
		th {
			@media (max-width: $mobile_width) {
				@apply max-w-[calc(100vw/3-30px)] break-words;
			}
		}
	}
}