.image-and-links {
  &__image {
    img {
      @apply w-full h-auto;
    }
  
    &.rounded {
      @apply overflow-hidden rounded-[16px] bg-sol-white bg-opacity-90 shadow-[0px_10px_30px_0px_rgba(0,0,0,0.10)] backdrop-blur-[12.5px];
    }
  }
  
  &__links {
    @apply md:mt-0 mt-[30px] md:pl-[60px];
  
    .image-and-links__link {
      @apply pt-[28px] px-0 md:pb-[48px] pb-[28px] border-t border-x-0 border-b-0 border-solid border-sol-green;
  
      a {
        @apply text-sol-charcoal hover:text-sol-royal-blue;      
      }
    }
  }
}