.ai-block-assessment-tool-results {
  @apply relative text-[14px] lg:pt-[100px] pt-[60px] px-0 pb-[30px] 
      before:content-[''] before:block before:absolute before:top-0 before:left-0 before:w-full before:bottom-[125px] before:z-[-1]
      after:content-[''] after:block after:absolute after:bottom-[55px] after:left-0 after:h-[150px] after:w-full after:z-[-1];

  &:after {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $ColorWhite 100%);
  }

  &.first-block {
    @apply lg:mt-[100px] md:mt-[80px] mt-[60px];
  }

  .inner {
    @apply pt-[52px] md:px-[40px] px-[20px] pb-[40px] shadow-[0px_10px_30px_-10px_rgba(37,1,68,0.2)] rounded-[6px] bg-sol-white;
  }

  .error {
    @apply text-center text-sol-pink;    
  }

  .success {
    @apply text-center text-sol-green;
  }

  .title {
    @apply text-center lg:mb-[60px] mb-[50px];
  }

  .wrapper {
    @apply bg-sol-white border-[1px] border-solid border-[#E9E6EC] shadow-[0px_10px_30px_-10px_rgba(37,1,68,0.2)] rounded-[6px] py-[33px] px-[42px];

    &:not(:last-of-type) {
      @apply mb-[40px];
    }
  }

  .score {
    @apply flex md:flex-row flex-col items-center gap-y-[30px];    

    &-title {
      @apply basis-0 md:text-left text-center flex-grow;
    }

    &-col {
      @apply min-w-[160px] text-center;
    }

    &-value {
      span {  
        background: linear-gradient(0deg, $ColorPink, $ColorPink) !important;
      }

      p {
        @apply text-[14px] font-normal leading-[18px] mt-[0.5rem];
      }
    }
  }

  .effectiveness {
    @apply p-0;

    .grid_box {
      @apply grid grid-cols-2;

      &>* {
        @apply py-[37px] md:px-[42px] px-[20px] md:flex md:flex-col md:col-span-1 col-span-2;

        &:first-of-type {
          @apply md:border-none md:border-r md:border-y-0 md:border-l-0 border-t-0 border-x-0 border-b border-solid border-sol-charcoal border-opacity-[10%];
        }
      }

      .grow {
        @apply md:basis-0 md:flex md:items-center md:justify-between md:flex-grow;  
      }
    }

    .inner-grid {
      @apply grid grid-cols-6 gap-y-[30px] basis-0 flex-grow;

      &>* {
        @apply col-span-3 lg:col-span-2 last-of-type:col-span-6 lg:last-of-type:col-span-2;
      }
    }

    &-title {
      @apply text-center font-bold mb-[20px];
    }

    &-score {
      @apply text-center;
    }

    &-value {
      @apply font-bold mb-[3px];
    }

    &-domain {
      @apply text-[12px];
    }

    .chart {
      aspect-ratio: 1.7;
    }
  }

  .scores {
    .scores-title {
      @apply font-bold mb-[31px] md:text-left text-center;
    }

    .line {
      @apply relative flex md:flex-row flex-col items-center gap-y-[10px] gap-x-[30px] py-[13px] px-0 border-t border-x-0 border-b-0 last-of-type:border-b border-solid border-sol-charcoal md:text-left text-center;

      &-title {
        @apply md:flex-grow basis-0;
      }

      .before {
        @apply absolute top-0 left-[-42px] h-full w-[4px];
      }
    }
  }

  .scores {
    @apply pt-[37px] px-[42px] lg:pb-[96px] pb-[50px];

    .line {
      &-required_value,
      &-graph_value {
        @apply font-bold md:w-[155px];        

        span {
          @apply text-[10px] uppercase;
        }
      }

      &.ineffective .line-graph_value {
        @apply text-sol-pink;        
      }
    }
  }

  .download {
    @apply bg-[url('../img/bg-pattern.jpg')] md:py-[100px] md:px-[32px] pt-[40px] px-[16px] pb-[50px] border-none;    
  
    form {
      @apply flex md:flex-row flex-col gap-y-[20px] flex-wrap;
    }

    .hs_email {
      @apply md:basis-0 md:mr-[-4px];

      input {
        @apply w-full;
      }
    }

    .hs-fieldtype-text label:not(.hs-error-msg) {
      @apply hidden;      
    }

    input[type="email"] {
      @apply bg-sol-white rounded-[4px] py-0 md:pr-[36px] md:pl-[32px] px-[32px] text-left h-[48px] border-[1px] border-solid border-sol-charcoal border-opacity-[.3];

      @include autocomleteBg($ColorWhite, $ColorDefault);

      &::placeholder {
        @apply text-sol-charcoal text-opacity-[.5] #{!important};
      }
    }

    .btn {
      @apply whitespace-nowrap;
    }

    .submitted-message {
      @apply text-sol-charcoal text-center lg:text-[21px] text-[18px] leading-[1.5];
    }

    .hs-error-msg,
    .hs_error_rollup .hs-error-msgs label {
      @apply text-sol-pink font-normal #{!important};
    }

    .section-title-wrap {
      @apply lg:mb-[4em] mb-[3em];

      .section-title {
        @apply mb-[1em];
      }

      p {
        @apply min-[1440px]:text-[18px] text-[16px];
      }
    }

    .legal-consent-container {
      @apply mb-0;

      span {
        @apply font-normal;
      }
    }

    .hs_submit {
      @apply block text-center w-full;
    }
  }

  .chart-legend {
    @apply mt-0 mx-auto mb-[28px] md:justify-start justify-center;
  }
}

.legal-consent-container {
  @apply text-left mb-[2em];

  label {
    @apply block;
  }

  .hs-richtext {
    @apply mb-[2em] text-[12px] leading-[calc(18/12)];
  }

  .input {
    @apply mb-[2em];
  }

  input[type="checkbox"] {
    @apply mt-[2px];
  }

  :is(p, span) {
    @apply text-[12px] leading-[calc(18/12)];
  }
}