.inherit-image-size {
    @apply max-w-full w-[inherit] h-[inherit];    
}

/*
 * Default WP Alignment Classes
 *****************************************************************************/

.aligncenter,
.alignleft,
.alignright {
    @apply block p-0;
}

.aligncenter {
    @apply float-none mt-[.5em] mx-auto mb-[1em];
}

.alignright {
    @apply lg:float-right lg:mt-[.5em] lg:mr-0 lg:mb-[1em] lg:ml-[1em];
}

.alignleft {
    @apply lg:float-left lg:mt-[.5em] lg:mr-[1em] lg:mb-[1em] lg:ml-0;
}