.rich-text {
  @apply overflow-y-auto overflow-x-hidden;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-semibold mb-[16px] mt-[32px];
  }

  h3 {
    @extend .text-3;
  }

  h4 {
    @extend .text-4;
  }

  h5 {
    @extend .text-5;
  }

  h6 {
    @extend .text-6;
  }

  p:not(.text-lg):not(.lg\:text-2xl),
  li,
  a {
    @apply text-[1.125rem] leading-[1.4];
  }

  a:not(.btn) {
    @apply font-semibold text-sol-royal-blue;
  }

  .btn>a {
    @apply text-[inherit];
  }

  p {
    @apply mt-[1.3em] mb-[1.3em] break-words;
  }

  >* {
    &:first-child {
      @apply mt-0;
    }

    &:last-child {
      @apply mb-0;
    }
  }

  form ul li {
    @apply pl-0 before:hidden;
  }

  ul li {
    @apply relative pl-[1.75rem] mb-[0.9rem] before:content-[''] before:absolute before:top-[10px] before:left-[0.4rem] before:w-[0.45rem] before:h-[0.45rem] before:rounded-[50%] before:bg-sol-charcoal;
  }

  ol {
    counter-reset: list-number;
    @apply list-none;

    li {
      @apply relative pl-[1.75rem] mb-[0.9rem] before:text-sol-charcoal before:absolute before:top-[4px] before:left-[2px] before:rounded-[50%] before:text-center before:text-[18px] before:leading-[1];

      &:before {
        counter-increment: list-number;
        content: counter(list-number) ". ";
      }
    }
  }

  blockquote {
    @apply my-[52px] mx-0 relative pl-[36px] border-l-2 border-y-0 border-r-0 border-solid border-sol-royal-blue;

    p {
      @apply text-[24px] leading-[1.4] font-normal mb-[24px];
    }

    cite {
      @apply text-[24px] leading-[1.4] font-normal;
    }
  }

  iframe {
    @apply w-full;
  }

  .responsive-video-embed {
    @apply w-full pb-[56.9%] relative h-0 box-content;

    iframe {
      @apply absolute top-0 left-0 w-full h-full;
    }
  }

  // Default WP Alignment Classes
  .aligncenter,
  .alignleft,
  .alignright {
    @apply block p-0;
  }

  .aligncenter {
    @apply float-none mt-[.5em] mx-auto mb-[1em];
  }

  .alignright {
    @apply lg:float-right lg:mt-[.5em] lg:mr-0 lg:mb-[1em] lg:ml-[1em];
  }

  .alignleft {
    @apply lg:float-left lg:mt-[.5em] lg:mr-[1em] lg:mb-[1em] lg:ml-0;
  }

  .wp-caption {
    @apply py-[5px] px-0 text-center border-[1px] border-solid border-transparent bg-transparent;
  }

  .wp-caption img {
    @apply inline;
  }

  .wp-caption p.wp-caption-text {
    @apply text-sol-charcoal mt-[5px] mx-0 mb-0 p-0 text-center text-[75%] italic font-thin;
  }

  .big-title {
    @apply min-[1440px]:text-[96px] text-[52px] min-[1440px]:leading-[100%] leading-[1.10];
  }
}