.ai-block-assessment-tool {
  @apply relative lg:pt-[100px] pt-[60px] px-0 pb-[30px] text-[14px] overflow-hidden before:content-[''] after:content-[''];

  &:before,
  &:after {
    @apply absolute block bottom-0 left-0 h-full w-full;
  }

  &:before {
    @apply bg-[url('../img/bg-pattern.jpg')] left-[33%] z-[-2];
  }

  &:after {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $ColorWhite 100%);
    @apply h-[150px] z-[-1];
  }

  &.first-block {
    @apply md:mt-[86px] mt-[68px];
  }

  .title+.quiz {
    @apply lg:mt-[90px] mt-[50px];
  }

  .step {
    @include shadow-inner();
    @apply pt-[40px] lg:px-[30px] px-[20px] pb-[30px];

    .question,
    .setting {
      @apply transition-opacity duration-[.1s] ease-linear;

      &:not(:first-child):not(.allowed) {
        @apply opacity-[.15] cursor-not-allowed;

        * {
          @apply pointer-events-none;
        }
      }
    }
  }

  .step-title {
    @apply mb-[26px] font-semibold;
  }

  .question {
    @apply pb-[40px] border-b border-x-0 border-t-0 border-solid border-sol-charcoal border-opacity-[.1] mb-[35px] last-of-type:mb-0 last-of-type:border-none;
  }

  .question-title,
  .question-subtitle {
    @apply font-bold leading-[1.3];
  }

  .question-subtitle {
    @apply mt-[10px] mb-[28px];
  }

  .settings {
    @apply mb-[40px];
  }

  .setting {
    @apply items-center gap-y-[30px];

    &:not(:last-of-type) {
      @apply lg:mb-[20px] mb-[40px];
    }

    &-title {
      @apply text-sol-charcoal text-opacity-[.5];
    }

    &-value .value {
      @apply text-sol-charcoal flex items-center justify-center border-[1px] border-solid border-sol-charcoal border-opacity-[.3] font-bold m-0 h-[48px] rounded-[4px]; 
    }

    &.active .setting {
      &-title {
        @apply text-sol-charcoal;
      }

      &-value .value {
        @apply border-sol-charcoal;        
      }      
    }

    &-input {
      @apply text-sol-charcoal w-full flex items-center justify-center border-[1px] border-solid border-sol-charcoal border-opacity-[.3] font-bold m-0 h-[48px] rounded-[4px] py-0 px-[15px]; 

      &:-webkit-autofill {
        @apply shadow-[0_0_0px_1000px_white_inset] transition-colors duration-[5000s] ease-in-out;
      }
    }
  }

  .range-slider {
    @apply h-[2px] bg-sol-charcoal bg-opacity-[.2];

    .range-slider__range {
      @apply bg-sol-charcoal;
    }

    .range-slider__thumb {
      &[data-lower] {
        @apply w-0;
      }

      &[data-upper] {
        @apply bg-sol-white w-[20px] h-[20px] border-2 border-solid border-sol-charcoal;

        &[data-active] {
          @apply bg-sol-charcoal relative border-2 border-solid border-sol-white shadow-[0px_3px_8px_-2px_rgba(37,1,68,0.3)] w-[22px] h-[22px] after:content-[''];

          &:after {
            @apply bg-sol-white absolute top-[50%] left-[50%] w-[10px] h-[10px] translate-x-[-50%] translate-y-[-50%] rounded-[50%] z-[1];
          }
        }
      }
    }
  }

  .option_box {
    @apply flex;

    span {
      @apply cursor-pointer flex-grow basis-0 leading-[1.3];
    }

    &:not(:last-of-type) {
      @apply mb-[20px];
    }

    input {
      @apply my-0 lg:mr-[27px] mr-[10px] ml-0 flex-shrink-0;

      &[type="radio"] {
        @apply bg-[url("../img/radio-unchecked-black.svg")] appearance-none bg-no-repeat bg-contain inline-block h-[16px] w-[16px] bg-[50%];

        &:checked {
          @apply bg-[url("../img/radio-checked-black.svg")];
        }
      }
    }

    span {
      @apply block;
    }
  }

  .bottom {
    @apply pt-[40px] border-t border-x-0 border-b-0 border-solid border-sol-charcoal border-opacity-[.1];

    .error,
    .success {
      @apply mb-[20px] text-center empty:hidden;
    }

    .error {
      @apply text-sol-pink;
    }

    .success {
      @apply text-sol-green;
    }

    .flex_box {
      @apply flex items-center justify-between;

      .ctas {
        @apply md:w-[160px] w-[46px] last-of-type:text-right;

        a,
        button {
          @apply whitespace-nowrap md:px-[36px] px-[13px] before:content-[''] lg:text-[14px] text-[0] font-bold text-sol-white;

          &:before {
            transform: translateX(3px);
            @apply md:hidden block w-[20px] h-[20px] bg-contain bg-[url('../img/arrow-purple.svg')] bg-no-repeat bg-center rotate-[180deg];
          }

          &:hover,
          &:focus {
            @apply before:bg-[url('../img/arrow-white.svg')] before:bg-no-repeat before:bg-center;            
          }

          &.next {
            @apply before:bg-[url('../img/arrow-white.svg')] before:bg-no-repeat before:bg-center;            

            &:before {              
              transform: translateX(0);
            }
          }
        }
      }

      .position {
        @apply flex-grow text-center;
      }
    }
  }

  .slick-slide {
    &:nth-of-type(1),
    &:nth-of-type(2) {
      .question-subtitle {
        @apply text-[#1eacfc];
      }

      .step:before {
        background: linear-gradient(120.94deg,#1eacfc .48%,#00eac3 95.81%);
      }
    }
  }

  .slick-list {
    @apply mt-0 mx-[-30px] mb-[-30px];

    .slick-slide {
      @apply mt-0 mx-[30px] mb-[30px];
    }
  }

  aside {
    @apply md:block hidden fixed left-0 top-[180px] z-[98];

    li {
      a {
        @apply relative pl-[80px] pointer-events-none cursor-none text-[14px] uppercase font-bold before:content-[''];

        &:before {
          @apply block w-[20px] h-[2px] absolute top-[50%] left-0 translate-y-[-1px] transition-all duration-[.3s] ease-linear;
        }
      }

      span {
        @apply opacity-0 transition-all duration-[.3s] ease-linear;
      }

      &.prev a {
        @apply cursor-pointer pointer-events-auto;

        span {
          @apply opacity-[.3];
        }
      }

      &.current,
      &.prev:hover {
        a {
          &:before {
            @apply w-[60px];
          }

          span {
            @apply opacity-[1];
          }
        }
      }

      &:nth-of-type(1),
      &:nth-of-type(2) {
        a {
          @apply text-[#1eacfc] before:bg-[#1eacfc];
        }
      }
    }
  }
}