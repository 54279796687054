.ai-block-domains {
  @apply lg:pb-[60px] pb-[40px] relative text-[14px] z-[1];

  .wrapper {
    @apply lg:my-0 lg:mx-[40px] lg:py-[47px] py-[40px] px-0 relative after:content-[''];

    &:after {
      @include media(desktop) {
        @apply absolute top-0 bottom-0 left-0 right-0 z-[-1] bg-[url('../img/domains-block-bg.svg')] bg-contain bg-[top_center] bg-no-repeat;
      }
    }

    .top,
    .bottom {
      @apply text-center;

      .title {
        @apply font-bold leading-none;
      }

      .title+.desc {
        @apply mt-[10px] leading-none;
      }
    }

    .top {
      @apply mb-[43px];
    }

    .bottom {
      @apply mt-[35px];
    }
  }

  .flex_box {
    @apply flex lg:flex-row flex-col gap-x-[40px] gap-y-[40px] text-center;

    .title {
      @apply text-sol-white h-[60px] flex items-center justify-center;
    }

    .label {
      @apply text-[12px] pt-[20px] px-[21px] pb-[5px];

      span {
        @apply block rounded-[4px] py-[10px] px-[9px] leading-none bg-[$ColorLightGray];
      }
    }

    .option {
      @apply leading-none py-[13px] px-[21px] last-of-type:mb-[26px];
    }

    .domain {
      @apply lg:flex-grow lg:basis-0 bg-sol-white border-[1px] border-solid border-[#E9E6EC] shadow-[0px_10px_30px_-10px_rgba(37,1,68,0.2)] rounded-[6px] overflow-hidden;

      .title {
        @apply mb-0;
      }

      &:nth-of-type(1) {
        .title {
          background: linear-gradient(92.02deg, $ColorPurple 0%, $ColorPink 100%);
        }

        .option {
          color: $ColorPurple;
        }
      }

      &:nth-of-type(2) {
        .title {
          background: linear-gradient(92.02deg, $ColorPrimary 0%, $ColorPrimary 100%);          
        }

        .option {
          @apply text-sol-royal-blue;
        }
      }

      &:nth-of-type(3) {
        .title {
          background: linear-gradient(92.02deg, $ColorOrange 0%, $ColorYellow 100%);
        }

        .option {
          color: $ColorOrange;
        }
      }
    }
  }
}