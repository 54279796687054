.llm-hero-block {
  @apply relative p-[220px_0_110px];

  .llm-hero-content__titles,
  .llm-hero-content__cta {
    @apply text-center;
  }

  .llm-hero-content__titles h2 {
    @apply font-normal;
  }

  .llm-hero-content__cta {
    @apply lg:mt-[80px] md:mt-[60px] mt-[40px];
  }
}