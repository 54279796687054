// Typography: Setting up global font-related properties for all the elements

::selection {
  background: var(--color-text-selection);
}

p::selection {
  background: var(--color-text-selection);
}

.text-1 {
  @apply md:text-[96px] text-[66px] leading-[1.1] tracking-[-0.12rem];
} 

.text-2 {
  @apply md:text-[52px] text-[38px] leading-[1.2];
}

.text-3 {
  @apply md:text-[46px] text-[32px] leading-[1.2];
}

.text-4 {
  @apply md:text-[38px] text-[26px] md:leading-[1.4] leading-[1.2];
}

.text-5 {
  @apply md:text-[28px] text-[22px] md:leading-[1.4] leading-[1.2];
}

.text-6 {
  @apply md:text-[22px] text-[20px] leading-[1.2];
}

.text-overline {
  @apply text-[18px] leading-[1.2];
}

.text-tag {
  @apply text-[14px] leading-[1.2] tracking-[10%];
}

.page-template-template-general-content {
  h1.size-72 {
    @apply md:text-[72px] text-[66px] leading-[1.1] tracking-[-0.12rem];
  }

  h2 a {
    @apply md:text-[52px] text-[38px] leading-[1.2];
  }
}