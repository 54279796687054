body.baunfire-homepage.baunfire-homepage--llm--nonav {
  .bg-default:not(.footer-inner) {
    @apply bg-transparent;
  }

  .symphonyai-navbar {
    .navbar-brand img {    
      @apply filter-none;
    }
    .header-inner {
      @apply bg-sol-white;
    }
    .header-inner::after {
      @apply hidden;
    }
  }

  .header-partnerLogo {
    @apply flex items-center after:content-[''] after:h-[50px] after:w-[2px] after:my-0 after:mx-[20px] after:block after:bg-[#52A9F5] after:bg-opacity-[.5];
  }

  .header-partnerLogo {
    img {
      @media (max-width: 767px) {
        @apply max-w-[120px] #{!important};
      }
    }

    &:after {
      @media (max-width: 767px) {
        @apply my-0 mx-[15px] h-[40px];
      }
    }

    .navbar-toggler {
      @apply hidden;
    }
  }
}