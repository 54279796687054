.section-cta-block {
  .section-cta__grid {
    @apply items-center grid md:grid-cols-2;    
  }

  .section-cta__image {
    @apply w-full align-top h-auto;
  }

  h2 {
    @apply font-bold text-[48px] leading-[1.2];
  }

  .text-primary-1 {
    @apply text-[#5000EA];
  }

  .text-primary-2 {
    @apply text-sol-green;
  }
}