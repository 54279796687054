.llm-datasets-block {
  @apply relative lg:py-[120px] md:py-[100px] py-[80px] px-0;

  .datasets {
    @apply shadow-[$llm_shadow] rounded-[$llm_radius] bg-sol-white md:pt-[40px] pt-[60px] desktop:px-[40px] md:px-[20px] px-[60px] desktop:pb-[80px] pb-[100px];
  }

  .datasets-container {
    @apply desktop:flex-nowrap flex-wrap flex justify-between gap-[60px];
  }

  .datasets-content {
    @apply desktop:max-w-[350px] max-w-full desktop:flex-[1] basis-[100%] desktop:pt-[50px] pt-0;
  }

  .datasets-title {
    @apply mb-0;
  }

  .datasets-text {
    @apply mt-[30px];
  }

  .datasets-data {
    @apply desktop:max-w-[660px] max-w-full flex flex-wrap gap-[30px_20px] items-stretch basis-full;

    >* {
      @apply basis-[calc(50%_-_15px)] max-w-[calc(50%_-_15px)];
    }

    >* {
      @media (max-width: 767px) {
        @apply basis-full max-w-full;
      }
    }    
  }

  .datasets-dataBox {
    @apply bg-sol-white shadow-[0px_3px_10px_0px_rgba(0,_0,_0,_0.10)] rounded-[12px] p-[18px] flex items-center;
  }

  .dataBox-icon {
    @apply h-[65px] w-[65px] relative bg-[#F1F3F9] rounded-[100px] p-[20px];

    img {
      @apply block max-h-[90%] max-w-[90%] h-auto w-auto absolute right-[50%] bottom-[50%] translate-x-[50%] translate-y-[50%];
    }
  }

  .dataBox-content {
    @apply flex-[1] pl-[16px];
  }

  .dataBox-stat {
    @apply text-[25px] font-bold;
  }

  .dataBox-text {
    @apply text-[16px];
  }
}