.video {
  &-wrap video {
    @apply max-w-full h-auto;  
  }
  
  &-embed {
    @apply block relative pb-[56.25%] h-0;
  
    iframe {
      @apply absolute top-0 left-0 w-full h-full;
    }
  }
}