.modal {
    --bs-modal-zindex: 1055;
    --bs-modal-width: 500px;
    --bs-modal-padding: 1rem;
    --bs-modal-margin: 0.5rem;
    --bs-modal-color: ;
    --bs-modal-bg: var(--bs-body-bg);
    --bs-modal-border-color: var(--bs-border-color-translucent);
    --bs-modal-border-width: var(--bs-border-width);
    --bs-modal-border-radius: var(--bs-border-radius-lg);
    --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) -(var(--bs-border-width)));
    --bs-modal-header-padding-x: 1rem;
    --bs-modal-header-padding-y: 1rem;
    --bs-modal-header-padding: 1rem 1rem;
    --bs-modal-header-border-color: var(--bs-border-color);
    --bs-modal-header-border-width: var(--bs-border-width);
    --bs-modal-title-line-height: 1.5;
    --bs-modal-footer-gap: 0.5rem;
    --bs-modal-footer-bg: ;
    --bs-modal-footer-border-color: var(--bs-border-color);
    --bs-modal-footer-border-width: var(--bs-border-width);
    z-index: var(--bs-modal-zindex);

    @apply fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none;


    @media (min-width: 576px) {
        --bs-modal-margin: 1.75rem;
        --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

        @apply bg-black bg-opacity-[0.5];
    }
    
    .btn-close {
        --bs-btn-close-color: #000;
        --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e);
        --bs-btn-close-opacity: 0.5;
        --bs-btn-close-hover-opacity: 0.75;
        --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
        --bs-btn-close-focus-opacity: 1;
        --bs-btn-close-disabled-opacity: 0.25;
        --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
        box-sizing: content-box;
        color: var(--bs-btn-close-color);
        background: black;
        opacity: 1;
        text-align: center;
        @apply flex absolute right-[3%] top-[3%] w-[25px] h-[25px] p-[10px] border-none rounded-full bg-sol-charcoal cursor-pointer;
    }

    &.fade:not(.show) {
        @apply transition-none opacity-0;
    }
    
    &.fade {
        transition: opacity .15s linear;

        @media (prefers-reduced-motion: reduce) {
            @apply transition-none;            
        }
    }

    .modal-body {
        padding: var(--bs-modal-padding);
        @apply relative flex-[1_1_auto] bg-sol-white;
    }
    
    .modal-content {
        color: var(--bs-modal-color);
        background-color: var(--bs-modal-bg);
        background-clip: padding-box;
        border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
        border-radius: var(--bs-modal-border-radius);
        @apply outline-none pointer-events-auto w-full flex-col flex relative;
    }
    
    &.show .modal-dialog {
        transform: translate(0, -50px);
        @apply transform-none;    
    }
    
    @media (prefers-reduced-motion: reduce) {
        &.fade .modal-dialog {
            @apply transition-none;
        }
    }
    
    &.show.fade .modal-dialog {
        @apply rounded-[18px] translate-y-0 mx-auto duration-[.3s] ease-out overflow-hidden;
    }
    
    @media (min-width: 1200px) {
        .modal-xl {
            --bs-modal-width: 1140px;
        }
    }
    
    @media (min-width: 992px) {
        .modal-lg,
        .modal-xl {
            --bs-modal-width: 800px;
        }
    }
    
    @media (min-width: 576px) {
        .modal-dialog {
            max-width: var(--bs-modal-width);
            @apply mx-auto;
        }
    }
    
    .modal-dialog {
        margin: var(--bs-modal-margin);
        @apply pointer-events-none w-auto relative;
    }
}