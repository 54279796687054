.ai-block-maturity-gap {
  @apply text-[14px] lg:pt-[200px] md:pt-[160px] pt-[100px] px-0 md:pb-[100px] pb-[80px];

  .maturity-gap {
    .title-wrap {
      @apply md:mb-[72px] mb-[42px];

      h4 {
        @apply text-sol-charcoal mt-[24px];
      }

      a {
        @apply mt-[24px];
      }
    }

    .stuff-wrap {
      @apply bg-sol-white md:pt-[30px] pt-[10px] md:px-[40px] px-[10px] md:pb-[40px] pb-[20px] shadow-[0px_10px_30px_-10px_rgba(37,1,68,0.20)] rounded-[6px] border-[1px] border-solid border-[#e9e6ec];

      .gaps {
        .line {
          @apply md:flex-row flex-col md:text-left text-center relative flex items-center border-t border-x-0 border-b-0 border-solid border-sol-charcoal border-opacity-[.1] py-[22px] px-0 gap-y-[30px] gap-x-[30px] last-of-type:border-b;

          &-title {
            @apply md:flex-grow basis-0;
          }

          .before {
            @apply md:left-[-42px] max-[420px]:left-[-6px] left-[-10px] w-[3px] h-[calc(100%-2px)] top-[1px] absolute;
          }

          &:last-of-type {
            @apply border-b-0 pb-0;
          }
        }

        .line-chart {
          @apply md:w-[63%] w-full;

          &-wrapper {
            @apply py-0 md:px-[20px] px-[10px] relative before:content-[''] after:content-[''];

            &:before {
              @apply block rounded-[40px] absolute bottom-0 left-0 h-[42px] w-full bg-[$ColorLightGray];
            }

            &:after {
              @apply block absolute bottom-[20.5p] md:left-[20px] left-[10px] h-[1px] md:right-[20px] right-[10px] bg-sol-charcoal bg-opacity-[.3];
            }

            .grid_box {
              @apply grid grid-cols-4 h-[76px];

              &>* {
                @apply relative before:content-[''] last-of-type:after:content-[''] before:left-0 last-of-type:after:right-0;

                &::before,
                &:last-of-type::after {
                  @apply absolute left-0 bottom-[15.5px] h-[10px] w-[1px] bg-sol-charcoal bg-opacity-[.3];
                }

                .left,
                .right {
                  @apply text-sol-charcoal absolute top-0 left-0 translate-x-[-50%] text-[10px];
                }

                .right {
                  @apply left-auto right-0;
                }
              }
            }

            .numbers {
              @apply relative;
            }

            .number {
              @apply bg-sol-white absolute bottom-[21.5px] translate-x-[-50%] translate-y-[50%] shadow-[0px_3px_10px_-2px_rgba(37,1,68,0.3)] md:p-[8px] p-[6px] md:text-[14px] text-[10px] font-bold z-[2];

              &.first {
                @apply text-sol-pink;
              }

              &.second {
                @apply text-sol-yellow;
              }

              &.third {
                @apply text-sol-green;
              }
            }
          }

          &--bottom {
            .line-chart-wrapper {
              @apply before:content-none after:content-none;

              .flex_box {
                @apply flex text-center md:text-[12px] text-[10px] gap-x-[1px];

                &>* {                  
                  &:before {
                    @apply content-[''] block border-solid border-sol-pink border-t-0 border-x-[1px] border-b-[1px] h-[10px] w-full mb-[6px];
                  }

                  &:nth-of-type(2)::before {
                    @apply border-sol-yellow;                    
                  }

                  &:nth-of-type(3)::before {
                    @apply border-sol-green;                    
                  }
                }

                span {
                  @apply inline-block px-[4px];
                }
              }
            }
          }
        }
      }

      .tool {
        .line {
          @apply md:p-0 pt-[20px] px-0 pb-[16px] md:flex-nowrap flex flex-row flex-wrap border-[1px] border-solid border-sol-charcoal border-opacity-[.1] items-center justify-between relative;

          h6,
          p {
            @apply text-sol-charcoal text-[14px] leading-[18px];
          }

          .left {
            @apply md:pr-[20px] md:text-left text-center md:mb-0 mb-[8px] md:flex-[0_0_40%] flex-[0_0_100%];

            p {
              @apply md:font-normal font-bold;
            }
          }

          .right {
            @apply md:flex-[0_0_60%] flex-[0_0_100%] md:justify-end items-center justify-center flex flex-row flex-nowrap;

            .right-col {
              @apply md:flex-[0_0_30%] flex-[0_0_35%] md:py-[10px] md:pr-0 md:pl-[40px] p-[8px];

              &:first-child {
                @apply md:flex-[0_0_40%] flex-[0_0_30%];
              }

              &.full-width {
                @apply flex-[0_0_100%];
              }

              input[type="text"] {
                @apply text-[$ColorSecondary] flex items-center justify-center border-[1px] border-solid border-sol-charcoal rounded-[4px] h-[48px] m-0 font-bold text-center w-full;
              }

              h6 {
                @apply md:text-[14px] text-[12px] md:font-bold font-normal;
              }
            }
          }

          .line-color {
            @apply absolute md:left-[-40px] left-[-10px] top-[1px] h-[calc(100%-2px)] w-[4px];
          }

          &.labels {
            @apply pt-[35px] pb-[20px];
          }
        }

        .submit-wrapper {
          @apply lg:mt-[40px] mt-[32px] text-right;

          a.btn {
            @apply px-[40px];
          }
        }
      }
    }

    &.maturity-gap-results {
      @apply md:mx-0;

      .stuff-wrap {
        @media (min-width: 0px) and (max-width: 767px) {
          @apply pt-[15px];
        }

        @media screen and (max-width: 420px) {
          @apply px-[4px];
        }
      }
    }
  }
}