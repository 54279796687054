.llm-footer-block {
  @apply md:p-[0_30px_30px_30px] p-[0_20px_20px_20px] relative z-[88];
}

body.baunfire-homepage .llmFooter-inner {  
  @apply bg-sol-charcoal rounded-[32px] lg:py-[120px] md:py-[100px] py-[80px] px-0 text-center w-full;

  .container {
    @apply max-w-[1120px];

    @media (max-width: 767px) {
      @apply p-[0_20px];
    }
  }

  .llmFooter-title {
    @apply mb-[30px];
  }

  .llmFooter-text {
    >* {
      @apply mb-[30px];
    }

    >*:last-child {
      @apply mb-0;
    }

    p {
      @apply md:text-[22px] text-[18px] leading-[1.38];
    }
  }

  .llmFooter-cta {
    @apply mt-[30px];
  }

  *:not(a) {
    @apply text-sol-white;
  }
}