//
// Resources
//

.ai-block-news-results { 
  
	.block_FR002 {
	  .block-grid {
		.block-item {
			@apply bg-[#F3F9FE] rounded-[12px];
		  
		  	&:nth-child(2) {
				grid-column: unset;
		  	}
  
		  	&:hover {
				@apply mb-[16px] mt-[-16px] bg-sol-white;			  
			  	box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);			  
		  	}
		}
	  }
	}
	
	.global-hero {
		padding: 50px 0;
	}
  
	.card {
		margin-bottom: 40px;
  
		.resource-thumbnail {
			height: 100px;
			background-size: cover;
			background-position: center center;
		}
  
		.content-wrapper {
			padding: 20px 40px;
		}
  
		.resource-meta {
			margin-bottom: 10px;

			span+span {
				float: right;
			}
		}

		.resource-link {
			font-weight: 600;
			margin-top: 10px;
			text-transform: uppercase;
			display: inline-block;
		}
	}
}
  
body.single-news {
	.global-hero {
		box-sizing: border-box;
		&.layout-ungated {
			padding-top: 110px;
			padding-bottom: 40px;
			@media (min-width: 768px) {
				padding-top: 155px;
			}
			.hero-image {
				display: block;
				width: 100%;
				height: 300px;
				background-size: cover;
				background-position: center center;
				border-radius: 16px;
				@media (min-width: 768px) {
					height: 100%;
					margin-left: 7%;
					width: 93%;
				}
			}
			.content-wrapper {
				padding-top: 45px;
				padding-bottom: 40px;
				.page-title {
					@media (min-width: 768px) {
						width: 107%;
					}
				}
			}
		}

		.resource-type {
			font-size: 14px;
			font-weight: 600;
			line-height: 120%;
			letter-spacing: 1.4px;
			text-transform: uppercase;
			margin-bottom: 20px;
		}
		.page-title { 
			font-size: 38px;
			line-height: 1.2;
			margin-bottom:13px;
			padding:0;
			@media (min-width: 768px) {
				font-size: 52px;
			}
		}
		.text-paragraph-large {
			font-weight: 400;
			margin-bottom: 28px;
		}
		.meta-wrapper {
			display: flex;
			justify-content: flex-start;
			.date {
				font-size: 18px;
				font-weight: 400;
				line-height: 140%;
				display: inline-block;
				margin-right: 10px;
			}
			.pipe {
				font-size: 18px;
				font-weight: 400;
				line-height: 140%;
				display: inline-block;
				margin-right: 10px;
			}
			.author {
				font-size: 18px;
				font-weight: 400;
				line-height: 140%;
				display: inline-block;
				strong {
					font-weight: 700;
				}
			}
		}
	}
	.global-main {
		padding-top: 40px;
		padding-bottom: 40px;
		@media (min-width: 768px) {
			padding-top: 120px;
			padding-bottom: 120px;
		}
		&.layout-ungated {
			background-color: #F3F9FE;
			.col-md-8, .col-md-9 {
				@media (min-width: 768px) {
					padding-right: 70px;
				}
				@media (max-width: 767px) {
					order: 2;
					margin-left: 0;
				}
			}
		}
		.share-sidebar {
			padding-bottom: 40px;
			.share-label {
				margin-bottom: 20px;
			}
			.share-buttons {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				.share-button {
					margin-right: 20px;
				}
			}
		}
	}
	.sidebar-subscribe {		
		/* hubspot form*/
		.hbspt-form {
			.hs-form { 
				@apply flex items-center gap-[10px];
			}
			.hs-error-msgs,
			.hs-email label,
			.legal-consent-container { 
				@apply hidden #{!important};
			}
			.hs-email .input input {
				@apply  bg-[var(--color-grey)] border border-solid border-transparent text-[var(--color-body)] text-[14px] h-[48px] py-[8px] px-[16px] w-full rounded-[3px] outline-none;

				&.error {
					@apply border border-solid border-sol-red;
				}
			}
			.hs-button {
				@apply bg-sol-royal-blue border border-solid border-sol-royal-blue text-sol-white py-[15px] px-[25px] text-[14px] font-bold w-[238px] rounded-[9999px] appearance-none text-center;
			}
			.submitted-message {
				@apply dark:text-sol-white text-charcoal;
			}
		}
	}
}