.block-llm-logos-box {
  @apply lg:py-[120px] md:py-[100px] py-[80px] px-0 relative;

  .logosBox-container {
    @apply shadow-[$llm_shadow] rounded-[$llm_radius] bg-sol-white lg:pt-[60px] pt-[40px] lg:px-[60px] md:px-[40px] px-[20px] lg:pb-[100px] md:pb-[80px] pb-[40px];
  }

  .logosBox-title {
    @apply mb-0;
  }

  .logosBox-text {
    @apply mt-[30px];
  }

  .logosBox-logos {
    @apply md:mt-[60px] mt-[40px] md:gap-[50px] gap-[30px] items-center flex flex-wrap;
  }
}