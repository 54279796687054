/**
  * Gravity Forms
  */
  form select, form .form-select-vertical__wrapper {
      border: 1px solid #25282E1A !important;
  }
  form div div select.invalid, form div div input.invalid, form div div textarea.invalid {
    border: 1px solid #FF1616 !important;
    color:#FF1616;
  }
  form div div select:focus, form div div input:focus, .gform_wrapper div div input:focus, gform_wrapper div div textarea:focus, gform_wrapper div div select:focus {
      border: 1px solid #0074E8 !important;
  }

  .ginput_container_name {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  .ginput_container_name span {
    display: flex;
    flex-direction: column;
  }

  .hs-error-msgs {
    color: red;
    font-size: 0.875em;
    margin-top: 4px;
  }

  form input.invalid::placeholder,
  form textarea.invalid::placeholder {
    color:#FF1616;
  }

  form div div input:focus::placeholder {
    color: #777 !important;
  }

  .nylon-hs-form .form-columns-1 .hs-dependent-field {
    width: 100% !important;
  }

  .hs-fieldtype-select .input {
    &::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4IDlMMTIgMTVMNiA5IiBzdHJva2U9IiMyNTI4MkUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K");    
      @apply content-[''] absolute right-[16px] bg-repeat bg-contain w-[24px] h-[24px] top-[50%] translate-y-[-50%] z-20;
    }
  }

  .block-request-a-demo,
  .gform_wrapper {
    form {
      input, 
      .input > select, 
      textarea {
        border: 1px solid #25282E1A !important;
        outline: none;
      }
      fieldset {
        .field {
          > .input {
            > input {
              &:focus {
                border: 1px solid #0074E8 !important;
              }
  
              &.invalid {
                &::placeholder {
                  color: #FF1616 !important;
                }
                border: 1px solid #FF1616 !important;
                color: #FF1616 !important;
              }
            }
          }
        }
      }
      .input {
        > select {
          &:focus {
            border: 1px solid #0074E8 !important;
          }
  
          &.invalid {
            &::placeholder {
              color: #FF1616 !important;
            }
            border: 1px solid #FF1616 !important;
            color: #FF1616 !important;
          }
        }
  
        > textarea {
          &:focus {
            border: 1px solid #0074E8 !important;
          }
          
          &.invalid {
            &::placeholder {
              color: #FF1616 !important;
            }
            border: 1px solid #FF1616 !important;
            color: #FF1616 !important;
          }
        }
      }
    }
  
    .hs-button-disabled {
      background: #25282E80 !important;
      cursor: not-allowed !important;
      border-color: #9b9c9e !important; 
    }
  }
  

.gform_wrapper {
  .gform_validation_errors h2.gform_submission_error {
    color: var(--color-red);
    @apply text-[24px] leading-[1.4];    
  }

  .gform-body {
    fieldset>legend {
      @apply hidden;
    }

    .gform_fields {
      flex-flow: row wrap;
      @apply flex justify-between;

      .gfield {
        flex: 0 0 100%;
        @apply max-w-full;

        &.gfield--width-half {
          flex: 0 0 calc(50% - 5px);
          max-width: calc(50% - 5px);
        }

        &.gfield--width-full {
          flex: 0 0 100%;
          @apply max-w-full;
        }

        .validation_message {
          color: var(--color-red);
          @apply mt-[0.5rem] text-[12px] leading-[1.2];
        }
      }

      .gfield.gfield--type-html p {
        @apply text-[14px] leading-[1.4];
      }

      .gfield.gfield--type-consent {
        .ginput_container_consent {
          @apply flex cursor-pointer;

          label.gform-field-label {
            @apply text-[14px] leading-[1.4] cursor-pointer;
          }

          input {
            flex: 0 0 200px;
            @apply appearance-none accent-sol-royal-blue h-[20px] bg-transparent cursor-pointer w-[20px] relative max-w-[20px];

            &:before {
              @apply content-[''] border-[2px] border-solid border-[#25282ebf] bg-center bg-[image:none] bg-[size:20px_20px] rounded-[4px] h-[20px] w-[20px] left-0 top-0 absolute overflow-hidden;
            }

            &:checked::before {
              background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMSIgeT0iMS41IiB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHJ4PSIxIiBmaWxsPSIjMDA3NEU4Ii8+CjxwYXRoIGQ9Ik00IDEwLjVMOC41IDE0LjVMMTYgNi41IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cmVjdCB4PSIxIiB5PSIxLjUiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgcng9IjEiIHN0cm9rZT0iIzAwNzRFOCIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=");
              @apply border-sol-royal-blue;
            }
          }
        }
      }
    }

    .gform-grid-col >label {
      @apply hidden;        
    }

    input,
    select,
    textarea {
      background-color: var(--color-grey);
      @apply border border-solid border-transparent text-[14px] py-[12px] px-[16px] w-full font-sans;

      &[aria-invalid='true'] {
        border-color: var(--color-red);
      }
    }

    .ginput_complex.ginput_container--name {
      flex-flow: row nowrap;
      @apply flex gap-[10px];

      >.gform-grid-col {
        flex: 0 0 calc(50% - 5px);
        max-width: calc(50% - 5px);

        input {
          @apply w-full;
        }
      }
    }

    .gfield {
      @apply block mb-[16px];

      >label {
        @apply hidden;
      }

      textarea {
        @apply w-full h-[112px] font-sans;
      }
    }

    .ginput_container_select {
      @apply relative;

      select {
        background-color: var(--color-grey);
        @apply appearance-none text-sol-charcoal;
      }

      &:before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE0IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyAxTDcgN0wxIDAuOTk5OTk5IiBzdHJva2U9IiMyNTI4MkUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K");
        @apply content-[''] absolute top-[50%] right-[16px] w-[16px] h-[10px] bg-[size:16px_10px] bg-center bg-no-repeat translate-y-[-50%];
      }
    }
  }

  .gform_footer .btn {
    @apply w-full;      
  }  

}