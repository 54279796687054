/*
* Mixin
*/

@mixin breakpoint($breakpoint, $width: min) {
	@media (#{$width}-width: $breakpoint) {
		@content;
	}
}

@mixin clearfix() {
	zoom: 1;
	@apply clear-both before:content-[''] before:table after:content-[''] after:table after:clear-both;
}

@mixin make-lead {
	@apply text-[1.125em] font-light leading-[1.333em];

	@include media(tablet) {
		@apply text-[1.313em] leading-[1.429em];
	}

	@include media(desktop) {
		@apply text-[1.313em] leading-[1.429em];
	}
}

@mixin transition($transition: 0.3s ease-out) {
	$prefixes: (
		"-webkit",
		"-ms",
		"-o"
	);

@each $prefix in $prefixes {
	#{$prefix}-transition: $transition;
}

transition: $transition;
}

@mixin make-inverse() {
	p,
	li,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	blockquote,
	span,
	.clean-w-icon {
		@apply text-sol-white;
	}

	li:before {
		@apply bg-sol-white;
	}

	ol li:before {
		@apply text-sol-white bg-transparent;
	}
}

@mixin shadow-inner() {
	@apply bg-sol-white relative overflow-hidden shadow-[0px_10px_30px_-10px_rgba(37,_1,_68,_0.2)] rounded-[0_0_6px_6px];

	&:before {
		background-color: $blue-to-green-gradient;
		@apply content-[''] absolute top-0 left-0 h-[6px] w-full;
	}
}

@mixin autocomleteBg($bgColor, $fontColor) {
	/* Change the white to any color */
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px $bgColor inset !important;
		-webkit-text-fill-color: $fontColor !important;
	}
}