// Global classes for blocks
.nylon-block {
  @apply relative;

  &.nylon-block--layout-dark {
    @include make-inverse();
  }
}

.block-bg-image {
  @apply absolute top-0 right-0 bottom-0 left-0;

  >img {
    @apply w-full h-full object-cover;
  }
}