.llm-image-grid-block {
  @apply lg:py-[120px] md:py-[100px] py-[80px] px-0 relative;

  .imageGrid-title,
  .imageGrid-sub_title {
    @apply text-center;
  }

  .imageGrid-sub_title {
    @apply font-normal mb-0;
  }

  .imageGrid {
    @apply lg:mt-[80px] md:mt-[60px] mt-[50px] gap-[20px_18px] justify-around flex-wrap flex;

    >* {
      @apply md:basis-[calc(33%_-_10px)] basis-full md:max-w-[calc(33%_-_10px)] max-w-full;
    }
  }

  .imageGrid-image {
    @apply opacity-0;

    img {
      @apply block m-[0_auto] h-auto;
    }
  }
}

@keyframes fadeIn {
  from {
    @apply opacity-0;
  }

  to {    
    @apply opacity-100;
  }
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  @apply opacity-0;
}

.fade-in-delay-2 {
  animation-delay: 0.5s;
}

.fade-in-delay-3 {
  animation-delay: 1s;
}