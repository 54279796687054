/**
FORM STYLES
*/

@mixin nylon-checkbox() {
	@apply relative flex cursor-pointer;

	>input.hs-input {
		@apply max-w-[20px] flex-[0_0_20px] relative w-[20px] cursor-pointer h-[20px] accent-sol-royal-blue appearance-none;

		&:before {
			@apply content-[''] border-[2px] border-solid border-[#25282ebf] bg-center bg-[size:20px_20px] bg-[image:none] rounded-[4px] h-[20px] w-[20px] left-0 top-0 absolute overflow-hidden;
		}

		&:checked::before {
			@apply border-sol-royal-blue;
			background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMSIgeT0iMS41IiB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHJ4PSIxIiBmaWxsPSIjMDA3NEU4Ii8+CjxwYXRoIGQ9Ik00IDEwLjVMOC41IDE0LjVMMTYgNi41IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cmVjdCB4PSIxIiB5PSIxLjUiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgcng9IjEiIHN0cm9rZT0iIzAwNzRFOCIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=")
		}
	}

	>span {
		@apply ml-0 text-[12px] leading-[1.5] pl-[12px] flex items-center;
	}
}

.gform_wrapper {
	input:not([type='checkbox']):not([type='radio']):not([type='submit']),
	select {
		background-color: var(--color-grey);
		color: var(--color-body);
		@apply text-[14px] py-[8px] px-[16px] h-[48px] border border-solid border-transparent rounded-[4px];
		@apply w-full #{!important};

		&.invalid {
			border-color: var(--color-red);
		}

		&:focus,
		&:active {
			@apply outline-none border-sol-charcoal;
		}
	}

	select {
		padding: 8px 36px 8px 16px !important;
	}

	textarea {
		color: var(--color-body);
		background-color: var(--color-grey);
		@apply border border-solid border-transparent rounded-[4px] text-[14px] py-[8px] px-[16px] min-h-[112px] font-sans;
		@apply w-full #{!important};
	}
}

.nylon-hs-form,
.gform_wrapper {
	form {
		@apply block w-full relative;

		.hs-fieldtype-intl-phone.hs-input {
			@apply w-full mb-[10px] mx-0 mt-0 #{!important};
			
			select {
				@apply mb-[10px] #{!important};
			}
		}

		.hs-richtext.hs-main-font-element h1 {
			font-style: normal;
			@apply mb-[30px] text-[22px] font-semibold leading-[120%] tracking-[0.44px];
		}

		>fieldset {
			@apply flex flex-col w-full min-w-full;

			@media(min-width: 768px) {
				flex-flow: row nowrap;
			}

			&.form-columns-1>.field {
				@apply w-full min-w-full;
			}

			&.form-columns-2 {
				@apply md:gap-[10px] gap-0;

				>.field {
					@apply w-full flex-[0_0_100%];

					@media(min-width: 768px) {
						width: calc(100% / 2 - 5px);
						flex: 0 0 calc(100% /2 - 5px);
					}
				}
			}
		}

		select {
			@apply w-full;
		}

		// Global styles for all layouts
		.field {
			float: none !important;
			@apply block mb-[24px];

			.input {
				@apply m-0 #{!important};
			}

			&.hs-fieldtype-selec .input select {
				@apply bg-transparent;
			}
		}

		ul.inputs-list {
			@apply mt-[0.75rem] pl-0;

			li.hs-form-checkbox {
				&:last-child {
					@apply mt-[.75rem];
				}
			}

			li {
				@apply leading-[1] text-[12px];
			}

			&.hs-error-msgs label {
				color: var(--color-red);
				@apply text-[12px] leading-[1.2];
			}
		}

		.hs-recaptcha {
			@apply mt-[24px];
		}
	}

	.legal-consent-container {
		.hs-richtext {
			@apply text-[12px] leading-[1.5] mt-[.75rem] #{!important};
		}

		.hs-dependent-field {
			.hs-fieldtype-booleancheckbox {
				@apply my-[28px] mx-0;

				label.hs-form-booleancheckbox-display {
					@include nylon-checkbox();
				}
			}
		}
	}

	.hs-form-checkbox-display {
		@include nylon-checkbox();
	}

	.hs-submit {
		@apply block mt-[1.75rem];

		.actions {
			input[type="submit"] {
				height: auto !important;
				@apply lg:max-w-[510px] lg:text-[18px] text-[14px] outline-none w-full py-[13px] px-[16px] border-none leading-[1] cursor-pointer font-bold whitespace-nowrap rounded-[9999px] text-sol-white bg-sol-royal-blue tracking-[0.28px];

				&:hover,
				&:focus {
					background-color: var(--color-navy-blue);
				}
			}
		}
	}

	&.nylon-hs-form--default {
		form {
			.field:not(.hs-fieldtype-checkbox):not(.hs-fieldtype-radio)>label {
				@apply hidden;
			}

			.field {
				&.hs-fieldtype-select {
					.input {
						@apply relative rounded-[4px];

						&:before {
							background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4IDlMMTIgMTVMNiA5IiBzdHJva2U9IiMyNTI4MkUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K");
							@apply content-[''] absolute right-[16px] bg-no-repeat bg-contain w-[24px] h-[24px] top-[50%] translate-y-[-50%];
						}

						select {
							@apply appearance-none text-[14px] py-[8px] px-[14px];
						}
					}
				}

				&.hs-fieldtype-checkbox {
					ul.inputs-list {
						@apply mt-[12px];

						li.hs-form-checkbox {
							@apply mb-[20px];

							label.hs-form-checkbox-display {
								@apply relative flex items-center cursor-pointer;

								>input.hs-input {
									@apply accent-sol-royal-blue h-[20px] cursor-pointer w-[20px] relative;

									&:before {
										@apply content-[''] border-[2px] border-solid border-[#25282ebf] rounded-[4px] w-[20px] h-[20px] top-0 left-0 absolute;
									}

									&:checked::before {
										@apply border-sol-royal-blue;
									}
								}

								>span {
									@apply leading-[1] pl-[12px];
								}
							}
						}
					}

					label {
						@apply text-[18px] leading-[1.4];
					}
				}

				&.hs-fieldtype-radio {
					ul.inputs-list {
						@apply mt-[12px];

						li.hs-form-radio {
							@apply mb-[20px];

							label.hs-form-radio-display {
								@apply relative flex items-center cursor-pointer;

								>input.hs-input {
									@apply w-[20px] h-[20px] accent-sol-royal-blue relative;

									&:before {
										@apply content-[''] border-[2px] border-solid border-[#25282E] rounded-[9999px] w-[20px] h-[20px] top-0 left-0 absolute;
									}

									&:checked::before {
										@apply border-sol-royal-blue;
									}
								}

								>span {
									@apply leading-[1] pl-[12px];
								}
							}
						}
					}

					label {
						@apply text-[18px] leading-[1.4];
					}
				}

			}

			input:not([type='checkbox']):not([type='radio']):not([type='submit']),
			select {
				background-color: var(--color-grey);
				color: var(--color-body);
				width: 100% !important;
				@apply rounded-[4px] border border-solid border-transparent h-[48px] py-[8px] px-[16px] text-[14px];

				&.invalid {
					border-color: var(--color-red);
				}

				&:focus,
				&:active {
					@apply border-sol-charcoal outline-none;
				}
			}

			select {
				padding: 8px 36px 8px 16px !important;
			}

			textarea {
				background-color: var(--color-grey);
				color: var(--color-body);
				width: 100% !important;
				@apply border border-solid border-transparent rounded-[4px] text-[14px] py-[8px] px-[16px] min-h-[112px] font-sans;
			}
		}
	}

	&.nylon-hs-form--underline {
		form {
			.field:not(.hs-fieldtype-checkbox):not(.hs-fieldtype-radio)>label {
				@apply hidden;
			}

			.field {
				.input {
					input:not([type='checkbox']):not([type='radio']):not([type='submit']) {
						@apply w-full h-[38px] py-[8px] px-0 rounded-none border-b border-x-0 border-t-0 border-solid border-[#25282e26];

						&:focus,
						&:active {
							@apply border-sol-charcoal outline-none;
						}

						&.invalid {
							border-color: var(--color-red);
						}
					}

					textarea {
						width: 100% !important;
						@apply border border-solid border-[#25282e26] rounded-none py-[8px] px-[16px] min-h-[112px] font-sans;
					}
				}

				&.hs-fieldtype-select {
					.input {
						@apply border border-solid border-[#25282e26] bg-transparent rounded-none;

						select {
							@apply py-[8px] px-0;
						}
					}
				}
			}
		}
	}

	&.nylon-hs-form--label {
		form {
			.field {
				>label {
					color: rgb(var(--charcoal) / 0.75);
					@apply block text-[12px] leading-[1.5] mb-[6px];
				}

				&.hs-fieldtype-select {
					.input {
						background-color: var(--color-grey);
						@apply rounded-[4px];

						select {
							@apply py-[8px] px-[14px];
						}
					}
				}
			}

			input:not([type='checkbox']),
			select {
				background-color: var(--color-grey);
				color: var(--color-body);
				width: 100% !important;
				@apply py-[8px] px-[16px] h-[38px] border border-solid border-transparent rounded-[4px];

				&.invalid {
					border-color: var(--color-red);
				}

				&:focus,
				&:active {
					@apply border-sol-charcoal outline-none;
				}
			}

			textarea {
				background-color: var(--color-grey);
				color: var(--color-body);
				width: 100% !important;
				@apply border border-solid border-transparent rounded-[4px] py-[8px] px-[16px] min-h-[112px] font-sans;
			}

		}
	}

	.submitted-message {
		@apply relative w-full mb-[24px];
	}
}

.hbspt-form .hs-richtext.hs-main-font-element h2 {
	@apply hidden #{!important};
}

.post-password-form {
	box-shadow: 0px 10px 30px -10px rgba(37, 1, 68, 0.20);
	border-radius: 0 0 6px 6px;
	@apply max-w-[90%] w-[600px] my-[200px] mx-auto text-center;

	.inner-form {
		@apply py-[50px] px-[100px];
	}

	h3 {
		@apply mb-[40px];
	}

	p {
		@apply m-0;
	}

	.input {
		border: 1px solid rgba(37, 1, 68, 0.3);
		@apply p-[15px] w-full mb-[30px] rounded-[4px] text-center tracking-[1px] leading-[18px] font-bold text-[14px];
	}

	.btn {
		@apply w-full;
	}

	.top-border {
		background: rgb(30, 172, 252);
		background: -webkit-linear-gradient(left, rgba(30, 172, 252, 1) 0%, rgba(0, 234, 195, 1) 100%);
		background: -o-linear-gradient(left, rgba(30, 172, 252, 1) 0%, rgba(0, 234, 195, 1) 100%);
		background: linear-gradient(to right, rgba(30, 172, 252, 1) 0%, rgba(0, 234, 195, 1) 100%);
		@apply w-full h-[6px];
	}
}

.post-password-message {
	font-size: var(--global--font-size-lg);
}

.hs-richtext,
.hs-richtext p {
	@apply text-[12px] leading-[1.5] mt-[.75rem] #{!important};
}
.legal-consent-container :is(p,span) {
	@apply text-[12px] leading-[1.5] #{!important};
}