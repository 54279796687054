.ai-block-doughnut-chart {
  @apply md:pb-[75px] pb-[30px];

  .chart-legend {
    @apply mt-0 mx-auto md:mb-[30px] mb-[20px] max-w-full w-[520px];
  }

  .chart {
    aspect-ratio: 0.95;
    @apply lg:max-h-[750px] max-h-[500px];
  }
}

.chart-legend {
  @apply text-sol-charcoal flex flex-wrap gap-x-[14px] gap-y-[9px] text-[10px] font-normal;

  .item {
    @apply flex items-center whitespace-nowrap leading-none;

    .color {
      @apply mr-[11px] w-[40px] h-[12px] rounded-[2px];
    }
  }
}