.ai-block.animate {
  .section-title.title-wipe {
    @apply inline-block relative opacity-100 text-transparent z-[2];

    // initial gray color
    &::before {
      @apply content-[attr(data-text)] absolute top-0 left-0 w-full h-full z-0 text-sol-charcoal text-opacity-[.2];
    }

    span {
      background-image: linear-gradient(120.94deg, $ColorPrimary 0.48%, $ColorGreen 77.81%);
      @apply relative inline-block text-transparent z-[1] bg-[size:0_100%] bg-no-repeat bg-transparent bg-clip-text transition-[background-size] duration-[2s] ease-in-out;
    }

    &.title-wipe-green span {      
      background-image: linear-gradient(120.94deg, $ColorGreen 0.48%, $ColorGreen 77.81%);
    }
  }

  // fire the wipe animation
  &.reveal1000 .section-title.title-wipe span {
    @apply text-transparent bg-[size:100%_100%];
  }
}