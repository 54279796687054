.ai-block-cta-section {
  @apply relative lg:pt-[50px] lg:py-[90px] lg:pb-[100px] py-[30px] px-[20px] text-center after:content-[''];

  &:after {
    @apply absolute block bottom-0 left-0 h-full w-full bg-[url('../img/bg-pattern.jpg')] rounded-[6px] z-[1];
  }

  .section-title+.ctas {
    @apply mt-[26px];
  }

  .ctas {
    @apply relative z-[2];
  }
}