/* BUTTONS */

.rich-text .btn {
	@extend .husl-btn;
	@extend .btn-primary;
}

.husl-btn {	
	@apply rounded-[9999px] font-semibold text-[16px] leading-none py-[0.8181rem] px-[1.75rem] relative max-w-full inline-flex border-[0.0625rem] border-solid border-transparent items-center cursor-pointer justify-center overflow-hidden whitespace-nowrap transition-all duration-[0.256s] ease-[cubic-bezier(0.075,0.82,0.165,1)] focus:outline-none focus:shadow-none;

	&.btn-mobile-full {
		@media(max-width: 767px) {
			@apply w-full;
		}
	}	

	&.btn-sm {
		@apply py-[.6933rem] px-[1.25rem];
	}

	&.btn-primary {
		@apply bg-sol-royal-blue text-sol-white border-sol-royal-blue;		

		&:hover,
		&.hover,
		&:active,
		&:focus,
		&.active {
			@apply bg-[var(--color-navy-blue)] border-[var(--color-navy-blue)];
		}
	}

	&.btn-charcoal15 {
		@apply bg-sol-charcoal bg-opacity-[.15] border-sol-charcoal border-opacity-[.15];

		&:hover,
		&.hover {
			@apply bg-sol-charcoal bg-opacity-[.5] border-sol-charcoal border-opacity-[.5];
		}

		&:active,
		&:focus,
		&.active {
			@apply bg-sol-charcoal bg-opacity-[.5] border-sol-charcoal border-opacity-[.5];
		}
	}

	&.btn-primary-dark,
	&.btn-white {
		@apply bg-sol-white text-sol-charcoal border-sol-white;

		&:hover,
		&.hover,
		&:active,
		&:focus,
		&.active {
			@apply text-sol-charcoal bg-sol-white bg-opacity-[.5] border-sol-white border-opacity-0;
		}
	}

	&.btn-primary-outline-dark,
	&.btn-white-outline {
        @apply text-sol-white border-sol-white bg-transparent;
		
		&:hover,
		&.hover,
		&:active,
		&:focus,
		&.active {
			@apply bg-sol-white text-sol-charcoal border-sol-white;
		}
	}


	&.btn-primary-outline {
		@apply text-sol-royal-blue border-sol-royal-blue bg-transparent;

		&:hover,
		&.hover {
			@apply bg-sol-royal-blue text-sol-white border-sol-royal-blue;
		}

		&:active,
		&:focus,
		&.active {
			@apply bg-[inherit] border-[inherit] text-[inherit];
		}
	}
}

.link-arrow {
	@apply text-[18px] will-change-[color] leading-none font-bold tracking-[0.0225rem] transition-colors duration-[0.256s] ease-[cubic-bezier(0.075,0.82,0.165,1)] relative whitespace-nowrap before:content-[''];

	&:before {
		@apply absolute w-[24px] h-[24px] opacity-[1] right-[calc(-24px+-4px)] bg-[size:_24px_24px];
	}

	&-blue {
		@apply text-sol-royal-blue;

		&:before {
			background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Imljb24vY2hldnJvbi1yaWdodCI+CjxwYXRoIGlkPSJ2ZWN0b3IiIGQ9Ik05IDZMMTUgMTJMOSAxOCIgc3Ryb2tlPSIjMDA3NEU4IiBzdHJva2Utd2lkdGg9IjIiLz4KPC9nPgo8L3N2Zz4K");
		}

		&:hover,
		&:active,
		&.hover {
			@apply before:opacity-[0.5] text-sol-royal-blue text-opacity-[.5];
		}
	}

	&-white {
		@apply text-sol-white;

		&:before {
			background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkgNkwxNSAxMkw5IDE4IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==")
		}

		&:hover,
		&:active,
		&.hover {
			@apply text-sol-white text-opacity-[.5] before:opacity-[0.5];
		}
	}
}

a.arrow-simple {
	@apply inline-block after:content-[''] hover:after:translate-x-[2px];

	&::after {
		@apply inline-block w-[20px] h-[10px] ml-[6px] bg-[url('../img/arrow-full-blue-right.svg')] bg-contain bg-center bg-no-repeat transition-transform duration-[.15s] ease-linear;
	}

	&.back {
		@apply after:hidden before:content-[''] hover:before:left-[-2px];

		&::before {			
			@apply relative inline-block w-[20px] h-[10px] mr-[6px] rotate-[180deg] bg-[url('../img/arrow-full-blue-right.svg')] bg-contain bg-center bg-no-repeat transition-[left] duration-[.15s] ease-linear left-0;
		}
	}
}