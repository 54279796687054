.testimonial-block {

  // Simple Testimonial Styles
  .simple-testimonial {
    @apply flex items-center justify-center flex-col;

    .author-logo {
      @apply max-w-[150px] mb-[24px] h-auto desktop:max-w-[250px] desktop:mb-[32px];
    }

    .testimonial-content {
      .testimonial-text {
        @apply mb-[24px];

        p {
          @apply font-semibold;
        }
      }

      .testimonial-author {
        @apply flex items-center justify-center flex-col;

        .avatar {
          @apply my-[24px] max-w-[80px] max-h-[80px] rounded-full;
        }

        .author-data {
          @apply text-[14px];

          .name {
            @apply inline-block font-bold py-0 px-[24px];
          }
        }
      }
    }
  }

  .large-avatar-testimonial {
    @apply flex flex-col;

    @include media(desktop) {
      @apply max-w-[80%] m-auto justify-center flex-row;
    }

    .avatar {
      @apply rounded-[30px] h-max desktop:max-w-[30%];
    }

    .testimonial-content {
      .testimonial-text {
        p {
          @apply font-semibold;
        }

        .author-logo {
          @apply max-w-full h-max mb-[24px];
        }
      }

      .testimonial-author .author-data {
        p {
          @apply text-[14px] mb-[24px];
        }

        .name {
          @apply font-bold;
        }
      }      
    }
  }
}