@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

// Variables are eclared in tailwind.css file
@import "utilities/variables";

// Utilities — SCSS untilites to use elsewhere
@import "utilities/mixins";
@import "utilities/responsive";
@import "utilities/other";

// Base — base styles
@import "base/rich-text";
@import "base/typography";

// Elements — reusable element styles
@import "elements/buttons";
@import "elements/forms";
@import "elements/gravity-forms";
@import "elements/icons";
@import "elements/images";
@import "elements/logos";
@import "elements/modal";
@import "elements/pagination";
@import "elements/password-protection";
@import "elements/tables";
@import "elements/video";

// Blocks — ACF block section styles
@import "blocks/all";

// Pages — WP template specific styles
@import "pages/blog";
@import "pages/news";
@import "pages/resources";
@import "pages/roi-calculator";
@import "pages/onetrust";

// Global
@import "global/custom-spacers-for-modules";

// LLM Template
@import 'llm/header';
@import 'llm/general';
@import 'llm/llm-blocks-container';
@import 'llm/llm-datasets';
@import 'llm/llm-divided-content';
@import 'llm/llm-footer';
@import 'llm/llm-hero';
@import 'llm/llm-image-grid';
@import 'llm/llm-logos-box';

// Issue with AOS and pointer-events
[data-aos] {
  pointer-events: all;
}