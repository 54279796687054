.cta-section-block {
  .simple-cta,
  .full-cta {
    @apply flex-col justify-center items-center gap-[24px] m-auto;
  }

  .stacked-cta {
    @apply flex-col;

    @include media(desktop) {
      @apply justify-between items-center flex-row;      
    }

    .cta-text {
      @apply desktop:mx-[50%];
    }

  }

  .ctas {
    @apply flex gap-[32px];
  }
}

.sm-mw {
  @apply max-w-full;

  @include media(tablet) {
    @apply max-w-[80%];
  }

  @include media(desktop) {
    @apply max-w-[50%];
  }
}

.md-mw {
  @apply max-w-full;

  @include media(tablet) {
    @apply max-w-[80%];
  }
}

.lg-mw {
  @apply max-w-full;
}