.icon {
  @apply bg-contain relative inline-flex;

  &.icon-sm {
    @apply w-[15px] h-[10px];
  }

  &.icon-check {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxOCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjUgMUw2LjUwMDA5IDExLjAwMDFMMS41IDYiIHN0cm9rZT0iIzI1MjgyRSIgc3Ryb2tlLXdpZHRoPSIxLjUiLz4KPC9zdmc+Cg==")
  }
}