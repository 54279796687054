.page-template-template-roi-calculator {
  .roi-results {
    @apply bg-[var(--color-grey)];
  }

  .hbspt-form {
    form {
      .field.hs-email {
        @apply mb-[32px];

        >label {
          @apply hidden;
        }

        .input {
          @apply w-full;

          input[type='email'] {
            @apply bg-[var(--color-grey)] w-full rounded-[6px] min-h-[46px] p-[8px_16px] border-[transparent] border border-solid text-[18px] ;

            &.invalid {
              @apply border-sol-red;
            }

            &:focus,
            &:active {
              @apply border-sol-charcoal outline-none;
            }
          }
        }
      }

      ul.inputs-list {
        @apply mt-[0.75rem] pl-0;

        li {
          @apply leading-none text-[12px];
        }

        &.hs-error-msgs label {
          @apply text-[12px] text-[#FF0000] leading-[1.2];
        }
      }

      .legal-consent-container {
        @apply mt-[12px];

        .hs-richtext {
          @apply text-[14px] leading-[1.4];
        }

        .hs-dependent-field {
          .hs-fieldtype-booleancheckbox {
            @apply m-[28px_0];

            label.hs-form-booleancheckbox-display {
              @apply relative flex cursor-pointer;

              >input.hs-input {
                @apply accent-[var(--color-blue)] relative w-[20px] max-w-[20px] cursor-pointer h-[20px] appearance-none flex-[0_0_200px];

                &:before {
                  @apply content-[''] overflow-hidden absolute top-0 left-0 w-[20px] h-[20px] rounded bg-center bg-[image:_none] bg-[size:_20px_20px] border-[2px] border-solid border-[rgb(var(--charcoal)_/_0.75)];
                }

                &:checked {
                  &:before {
                    @apply border-[var(--color-blue)];
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMSIgeT0iMS41IiB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHJ4PSIxIiBmaWxsPSIjMDA3NEU4Ii8+CjxwYXRoIGQ9Ik00IDEwLjVMOC41IDE0LjVMMTYgNi41IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cmVjdCB4PSIxIiB5PSIxLjUiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgcng9IjEiIHN0cm9rZT0iIzAwNzRFOCIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=")
                  }
                }
              }

              >span {
                @apply ml-0 text-[14px] pl-[12px] leading-[1.4];
              }
            }
          }
        }
      }

      .hs-submit {
        @apply mt-[36px];

        .actions {
          .hs-button {
            @extend .husl-btn;
            @extend .btn-primary;
          }
        }
      }
    }
  }

  .calc-padding {
    @apply pt-[6rem] pb-[32px];

    @include media(tablet_up) {
      @apply pt-[8.25rem];
    }

    @include media(desktop) {
      @apply pt-[11.875rem];
    }

    &.right-side {
      @apply pt-0;

      @include media(desktop) {
        @apply pt-[190px];
      }
    }
  }
}

.roi-calculator {
  @apply relative;

  .content-layers {
    @apply relative z-[1];
  }

  .bg-layers {
    @apply absolute top-0 right-0 h-full w-3/12 z-0 bg-[color:_var(--color-grey)];
  }

  .roi-calc {
    @apply relative pt-[80px] pb-[32px] z-[2];

    @include media(desktop) {
      @apply py-0;
    }
  }

  .gradient-text {
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    background: linear-gradient(90.5deg, $ColorPrimary 0%, $ColorGreen 75.61%);
    @apply bg-sol-royal-blue relative inline-block opacity-100 text-transparent z-[2] bg-[size:_100%] bg-clip-text;
  }

  .bold {
    @apply font-semibold;
  }

  .tooltip {
    @apply relative rounded-[50%] w-[17px] h-[17px] align-middle cursor-pointer inline-block opacity-100 m-[-2px_0_0_3px] border border-solid border-[var(--color-blue)] bg-transparent bg-[url("../img/tooltip-i.svg")] bg-[size:auto_56%] bg-center bg-no-repeat;

    @include media(tablet_up) {
      @apply inline-block;
    }

    &::before,
    &::after {
      @apply absolute hidden;
    }

    &::before {
      @apply content-[''] w-[6px] h-[6px] bottom-[calc(100%_+_4px)] border-t-[6px] border-t-[rgba(0,0,0,0.8)] border-l-[6px] border-r-[6px] border-solid border-x-transparent;

      @include media(tablet_up) {
        @apply left-[50%] translate-x-[-50%] border-t-[rgba(0,0,0,0.7)];
      }
    }

    &::after {
      @apply content-[attr(data-tip)] text-sol-white bottom-[calc(100%_+_10px)] w-auto min-w-[250px] rounded-[6px] p-[10px] leading-[18px] text-center font-semibold text-[12px] right-[-8px] bg-black bg-opacity-[.8];

      @include media(tablet_up) {
        @apply left-[50%] translate-x-[-50%] bg-black bg-opacity-[.7];
      }
    }

    &:hover {
      @apply bg-sol-royal-blue bg-[image:url("../img/tooltip-i-hover.svg")] bg-[size:auto_56%] bg-center bg-no-repeat before:block after:block;
    }
  }

  .small {
    @apply text-[12px] leading-[1.2];
  }

  .white-bg {
    @apply relative bg-sol-white;

    @include media(desktop) {
      @apply w-[calc(100%_+_10px)];
    }

    &::after {
      @apply content-[''] absolute top-0 right-0 z-0 h-full w-screen bg-[inherit];
    }

    >.row {
      @apply relative z-[1];
    }
  }

  .summary {
    .summary-title {
      @apply mb-[20px];

      @include media(full_width) {        
        @apply max-w-[55%];
      }
    }

    .summary-outputs {
      @apply bg-sol-white shadow-[0px_10px_30px_-10px_rgba(37,_1,_68,_0.2)];
    }

    .total-savings {
      @apply p-[20px_30px] rounded-[6px_6px_0px_0px] mix-blend-normal;

      * {
        @apply text-sol-white;
      }

      .result.total {
        &.one-bil {
          @include media(tablet_up) {
            @apply text-[27px];
          }

          @include media(full_width) {
            @apply text-[40px];
          }
        }

        &.one-tril {
          @include media(tablet_up) {
            @apply text-[26px];
          }

          @include media(full_width) {
            @apply text-[37px];
          }
        }
      }
    }

    .other-savings {
      @apply p-[16px_30px_32px] rounded-[0px_0px_6px_6px];      

      .saving-category {
        @apply border-b border-x-0 border-t-0 border-solid border-[rgba($ColorSecondary,0.1)] p-[18px_0_14px];
      }

      .saving-form {
        @apply p-[32px_0_0];
      }

      .category-title {
        @apply text-sol-charcoal mb-[10px] text-[12px] leading-[1.5];
      }

      .saving-line-items {
        p {
          @apply text-sol-charcoal mb-0 text-base;
        }

        .label {
          @apply text-sol-charcoal font-normal;
        }
      }
    }
  }

  .intro p {
    @apply text-base leading-normal;    
  }

  .inputs {
    @apply mb-[20px];

    .input-rows {
      @apply md:mt-0 mt-[24px];
    }

    .input-box {
      @apply bg-sol-white relative rounded-[6px] mt-[40px] mb-[40x] p-[36px_28px_16px] transition-all duration-[0.15s] ease-linear;

      &::before {
        @apply content-[''] absolute block h-[4px] w-full bottom-full left-0 bg-[var(--color-teal)];
      }

      &:hover {
        @apply bg-sol-white shadow-[0px_6px_10px_0px_rgba(0,_0,_0,_0.08)];
      }
    }

    .input-box-title {
      @apply mb-[22px];
    }

    .input-row {
      @apply flex mt-[4px] mb-[4px] justify-between items-center md:flex-row flex-col md:flex-wrap;

      &:not(.select-box) {
        @include media(desktop) {
          @apply gap-[20px] flex-wrap flex-row;
        }
      }

      .input-label,
      .input-range,
      .input-result {
        @apply mb-[16px];

        @include media(tablet_up) {
          @apply mr-[3%];
        }

        @include media(full_width) {
          @apply mr-[3%];
        }

        &:first-child {
          @apply flex-[0_0_100%] max-w-full;

          @include media(desktop) {
            @apply flex-[0_0_35%] max-w-[35%];
          }
        }

        &:last-child {
          @apply mr-0 max-w-full w-full flex-[0_0_100%];

          @include media(tablet_up) {
            @apply flex-[0_0_47%] max-w-[47%] w-[47%];
          }

          @include media(desktop) {
            @apply flex-[0_0_25%] max-w-[25%] w-[25%];
          }
        }
      }

      .input-label {
        @apply text-[14px] leading-[1.2] transition-colors duration-[.256s] ease-linear text-sol-charcoal text-opacity-[.45];
      }

      .input-range {
        @apply appearance-none w-full bg-transparent bg-[size:auto_2px] bg-no-repeat bg-[position:left_center] flex-[0_0_100%] cursor-pointer;        

        @include media(tablet_up) {
          @apply flex-[0_0_50%] w-[50%];
        }

        @include media(desktop) {
          @apply flex-[0_0_calc(30%_-_20px)] w-[calc(30%_-_20px)] max-w-[calc(30%_-_20px)];
        }

        &:focus +.input-result {
          @apply border-sol-charcoal border-opacity-[.5];    
        }

        &::-webkit-slider-runnable-track {
          @apply border-sol-charcoal border-opacity-[.15] h-[2px];
        }

        &::-moz-range-track {
          @apply border-sol-charcoal border-opacity-[.15] h-[2px];
        }

        &::-webkit-slider-thumb {          
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiByeD0iMTAiIGZpbGw9IndoaXRlIi8+CjxjaXJjbGUgY3g9IjEwIiBjeT0iMTAiIHI9IjYiIGZpbGw9IiMwMDc0RTgiIGZpbGwtb3BhY2l0eT0iMC40Ii8+CjxyZWN0IHg9IjEiIHk9IjEiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgcng9IjkiIHN0cm9rZT0iIzI1MjgyRSIgc3Ryb2tlLW9wYWNpdHk9IjAuMzUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K");          
          @apply bg-sol-white appearance-none opacity-100 w-[20px] h-[20px] bg-center -mt-[10px] transition-[background] duration-[.15s] ease-linear rounded-[50%] bg-no-repeat bg-[size:20px_20px];
        }

        &::-moz-range-thumb {
          @apply bg-sol-white appearance-none w-[14px] h-[14px] border-[2px] border-solid border-sol-charcoal rounded-[50%];
        }
      }

      .input-result {
        @apply rounded-[4px] p-[10px] text-center text-[18px] font-normal border-none text-sol-charcoal bg-[var(--color-grey)] focus:border-sol-charcoal;

        &[data-suffix] {
          @apply pr-[48px];
        }

        &[data-suffix="%"] {
          @apply bg-[url('../img/percent-tag.svg')] bg-[size:auto_100%] bg-[position:right_center] bg-no-repeat;
        }

        &[data-suffix="mins"] {
          @apply bg-[url('../img/mins-tag.svg')] bg-[size:auto_100%] bg-[position:right_center] bg-no-repeat;
        }

        &[data-suffix="$/yr"] {
          @apply bg-[url('../img/dollar-yr-tag.svg')] bg-[size:auto_100%] bg-[position:right_center] bg-no-repeat;
        }
      }

      &.select-box {
        .input-label,
        select {
          @apply flex-[0_0_100%];
        }

        select {          
          @apply w-full rounded-[4s] font-normal appearance-none text-[14px] text-sol-charcoal text-opacity-[.75] p-[8px_40px_8px_16px] transition-[border-color] duration-[.15s] ease-linear border-[1px] border-solid border-sol-charcoal border-opacity-[.3]
                bg-[image:url("../img/select-arrow.svg")] bg-no-repeat bg-[position:right_20px_center] bg-[size:12px_auto] bg-[color:var(--color-grey)] after:border-sol-charcoal focus:border-sol-charcoal;
        }
      }

      &:hover {
        .input-label {
          @apply text-sol-charcoal;
        }

        .input-range::-webkit-slider-thumb {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiByeD0iMTAiIGZpbGw9IndoaXRlIi8+CjxjaXJjbGUgY3g9IjEwIiBjeT0iMTAiIHI9IjYiIGZpbGw9IiMwMDc0RTgiLz4KPHJlY3QgeD0iMSIgeT0iMSIgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiByeD0iOSIgc3Ryb2tlPSIjMjUyODJFIiBzdHJva2Utb3BhY2l0eT0iMC43NSIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=");          
        }
      }
    }
  }
}

.roi-results {
  .metrics {
    @apply pl-0;

    li {
      @apply flex text-[14px] md:items-center items-start md:flex-row flex-col flex-nowrap py-[12px] px-0 justify-between;

      &:not(.subtitle) {
        @apply border-t border-x-0 border-b-0 border-solid border-sol-charcoal border-opacity-[.15];
      }

      &.purple,
      &.blue,
      &.orange {
        @apply relative;

        &::before {
          @apply inline-block absolute w-[4px] top-0 desktop:left-[-40px] left-[-30px] h-[calc(100%+1px)] content-[''];
        }
      }

      &.purple {
        @apply before:bg-[$ColorPurple];
      }

      &.blue {
        @apply before:bg-sol-royal-blue;        
      }

      &.orange {
        @apply before:bg-[$ColorOrange];        
      }

      &.subtitle .label {
        @apply font-bold;
      }

      span {
        @apply inline-block leading-[1.4];

        &.value {
          @apply w-[140px] font-semibold;

          @include media(tablet_up) {
            @apply text-left flex-[0_0_115px] ml-[24px];
          }
        }
      }
    }
  }

  .results-block {
    @apply rounded-[6px] mt-[20px] mb-[20px] desktop:p-[40px] p-[30px];

    &.total-savings {
      @apply flex items-center flex-col flex-nowrap;

      @include media(tablet_up) {
        @apply flex-row justify-between;
      }
    }
  }

  .call-to-action {
    @apply p-[40px_24px] rounded-[6px] mt-[20px];

    @include media(wide) {
      @apply p-[95px_40px_100px];
    }

    a.btn {
      @apply desktop:mt-[48px] mt-[32px];
    }
  }

  .explanations {
    .explanation {
      @apply m-[24px_0];

      .counter {
        @apply mb-[32px];

        div.number {
          -webkit-text-fill-color: transparent;
          background: linear-gradient(90deg, $ColorPrimary 0%, $ColorPurple 100%);
          @extend .text-1;
          @apply inline-block my-0 mx-auto font-semibold bg-clip-text;
        }
      }
    }
  }
}

.title-wipe {
  @apply inline-block relative opacity-100 text-transparent z-[2];
  
  &::before {
    @apply content-[attr(data-text)] absolute top-0 left-0 w-full h-full z-0 text-sol-charcoal text-opacity-[.2];
  }

  span {
    animation: updateBgSizeTitle 3s ease-in-out forwards;
    background-image: linear-gradient(90deg, white .48%, white 77.81%);
    @apply relative inline-block text-transparent bg-no-repeat bg-transparent z-[1] bg-[size:0_100%] bg-clip-text;
  }
}

@keyframes updateBgSizeTitle {
  0% {
    @apply bg-[size:0_100%];
  }

  100% {
    @apply bg-[size:100%_100%];
  }

}