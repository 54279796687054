/*
* Blog
*/

.global-hero {
	@apply p-[50px_0];
}

.card {
	@apply mb-[40px];
}


.post-link {
	@apply inline-block uppercase mt-[10px] font-semibold;
}

.post-meta {
	@apply mb-[10px];

	ul {
		@apply list-none;
	}
}

.post-thumbnail {
	@apply h-[150px] bg-cover bg-center;
}