//
// Resources
//

body.post-type-archive-resource,
body.tax-resource_type {
	.global-hero {
		@apply p-[50px_0];
	}

	.card {
		@apply mb-[40px];

		.resource-thumbnail {
			@apply h-[100px] bg-cover bg-center;
		}

		.content-wrapper {
			@apply p-[20px_40px];
		}

		.resource-meta {
			@apply mb-[10px];

			span+span {
				@apply float-right;
			}
		}

		.resource-link {
			@apply font-semibold mt-[10px] uppercase inline-block;
		}
	}
}



body.single-glossary .glossary-hero {
	@apply pt-[140px] pb-[60px] #{!important};
}


body.single-resources,
body.single-authors,
body.single-glossary {
	.global-hero {
		@apply box-border;

		&.glossary-hero {
			@apply min-h-[100px];
		}

		&.layout-ungated {
			@apply pt-[190px] pb-[60px] lg:pb-[125px] lg:pt-[240px];

			.hero-image {
				@apply block w-full bg-contain bg-center rounded-[16px] h-full max-h-none lg:pt-0 pt-[60px];
			}

			.content-wrapper {
				@apply pt-[60px] lg:pb-[40px] pb-0;

				.page-title {
					@apply lg:w-full;

					&.page-title--medium {
						@apply text-[48px] #{!important};
					}

					&.page-title--large {
						@apply lg:text-[40px] text-[30px] #{!important};
					}
				}
			}
		}

		&.layout-gated {
			@apply lg:pt-[200px] pt-[128px] lg:pb-[134px] pb-[60px];

			.form-grid {
				@apply relative;

				.form-wrapper {
					@apply relative lg:max-w-[400px] bg-sol-white box-border p-8 lg:translate-y-[-40px] translate-y-0 shadow-[0px_10px_30px_0px_rgba(0,_0,_0,_0.10)] lg:mb-0 mb-[60px];
				}
			}
		}

		.resource-type {
			@apply text-sm font-semibold uppercase mb-[20px] leading-[120%] tracking-[1.4px];
		}

		.page-title {
			@apply font-semibold mb-[30px] desktop:text-[76px] lg:text-[56px] text-[48px] leading-[110%] tracking-[-1.52px];
		}

		.subtitle {
			@apply font-semibold mb-[30px] desktop:text-[36px] text-[28px];
		}

		.meta-wrapper {
			@apply flex justify-start;

			.date,
			.pipe {
				@apply text-[18px] font-normal leading-[140%] inline-block mr-[10px];
			}

			.author {
				@apply text-[18px] font-normal leading-[140%] inline-block;

				strong {
					@apply font-bold;
				}
			}
		}
	}

	.global-main {
		@apply lg:py-[120px] py-[40px];

		&.layout-ungated {
			@apply bg-[#F3F9FE];

			.col-md-8 {		
				@media (max-width: 767px) {
					@apply order-[2] ml-0;
				}
			}
		}

		&.layout-gated {
			@include make-inverse();
			@apply bg-sol-charcoal;
		}

		img {
			@apply h-auto;
		}

		.share-sidebar {
			@apply pb-[40px];

			.share-label {
				@apply mb-[20px];
			}

			.share-buttons {
				@apply flex justify-start items-center;

				.share-button {
					@apply mr-[20px];
				}
			}
		}

		&.no-content {
			@apply py-0 #{!important};
		}
	}

	.latest-insights {
		@apply lg:pt-[110px] pt-[60px] pb-[140px];

		.section-title {
			@apply text-center;
		}
	}
}

body.single-resources,
body.single-authors {
	.global-hero {
		.hero-image {
			@apply bg-contain mb-0 w-full rounded-none bg-top bg-center bg-no-repeat bg-cover mb-0 w-full min-[991px]:h-[385px] h-[350px] rounded-[15px];
			&__wrapper {
				@apply items-start flex justify-start;
			}
		}
		&.layout-gated .hero-image__wrapper {
			@apply lg:mt-[-40px];
		}
		&.layout-ungated .hero-image__wrapper {
			@apply lg:mt-[45px];
		}
	}

	.share-wrapper {
		.share-sidebar {
			@apply flex lg:mt-[45px] mt-[40px] w-full flex-col;
		}
		.share-label {
			@apply mb-[20px];
		}
		.share-buttons {
			@apply w-full flex m-0 gap-[20px];
		}
	}

	.layout-gated {
		.form-grid {
			@apply flex justify-start relative;
		}

		.form-wrapper {
			@apply bg-sol-white box-border p-[30px] relative mt-[30px] max-w-[unset] transform-none shadow-[0px_10px_30px_0px_rgba(0,_0,_0,_0.10)] min-[991px]:max-w-[400px];

			h5,
			p {
				@apply text-sol-charcoal;
			}

			h5 {
				@apply mb-[20px];
			}
		}
	}
}

.truncate-3-lines {
	display: -webkit-box; 
	-webkit-line-clamp: 3; 
	-webkit-box-orient: vertical; 
	
	@apply overflow-hidden text-ellipsis;
}

body.single-resources {
	.sidebar-subscribe {		
        /* hubspot form*/
        .hbspt-form {
            .hs-form { 
                @apply flex items-center gap-[10px];
            }
            .hs-error-msgs,
            .hs-email label,
            .legal-consent-container { 
                @apply hidden #{!important};
            }
            .hs-email .input input {
                @apply  bg-[var(--color-grey)] border border-solid border-transparent text-[var(--color-body)] text-[14px] h-[48px] py-[8px] px-[16px] w-full rounded-[3px] outline-none;

                &.error {
                    @apply border border-solid border-sol-red;
                }
            }
            .hs-button {
                @apply bg-sol-royal-blue border border-solid border-sol-royal-blue text-sol-white py-[15px] px-[25px] text-[14px] font-bold w-[238px] rounded-[9999px] appearance-none text-center;
            }
            .submitted-message {
                @apply dark:text-sol-white text-charcoal;
            }
        }
	}
}