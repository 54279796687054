.contact-form-block {
  @apply relative lg:pt-[234px] md:pt-[160px] pt-[140px] px-0 md:pb-0 pb-[80px];

  &.contact-form--dark .rich-text * {
    @apply text-sol-white;    
  }

  .bg-layer {
    @apply bg-sol-charcoal absolute top-0 left-0 right-0 md:bottom-[120px] bottom-[60px];
  }

  .bg-layer img {
    @apply w-full h-full object-cover;
  }

  .form-wrapper {
    @apply bg-sol-white lg:mt-0 md:mt-[34px] mt-[104px] min-h-[280px] relative shadow-[0px_10px_30px_0px_rgba(0,0,0,0.1)] lg:p-[30px] py-[30px] px-[20px];
  }
}