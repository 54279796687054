.resources-row {
  @apply grid md:gap-y-[60px] gap-y-[30px] gap-x-[20px] lg:grid-cols-3 md:grid-cols-2 grid-cols-1;
}

.searchandfilter {
  @apply my-[52px] mx-auto max-w-[1180px] relative z-[88];

  > ul {
    @apply p-0 md:flex md:justify-between;

    >li {
      @apply md:mb-0 mb-[30px] shadow-[0px_0px_30px_0px_rgba(0,0,0,0.10)] cursor-pointer relative bg-sol-white rounded-[15px] pb-0 overflow-hidden w-full md:w-[calc(50%-12px)];

      &.open {        
        @apply overflow-visible rounded-bl-none rounded-br-none;

        h4 {
          @apply bg-[url('../img/arrow-up.svg')] z-[102];
        }

        >ul {
          @apply z-[101];
        }
      }

      h4 {
        background-position: right 30px top 15px;
        @apply pointer-events-none pt-[10px] px-[20px] pb-[20px] bg-sol-white text-[18px] font-normal leading-[100%] tracking-[0.36px] relative z-[100] bg-[url('../img/arrow-down.svg')] bg-no-repeat;
      }

      >ul {
        @apply bg-sol-white flex-wrap flex p-[20px] left-0 w-full top-[100%] absolute shadow-[0px_0px_30px_0px_rgba(0,0,0,0.10)] rounded-bl-[15px] rounded-br-[15px] border-t border-x-0 border-b-0 border-solid border-black border-opacity-[.2];

        li {
          @apply cursor-pointer;

          &.sf-option-active label {
            @apply border-sol-royal-blue text-sol-royal-blue;            
          }

          input {
            @apply hidden;
          }

          label {
            @apply rounded-[30px] text-[15px] py-[5px] px-[15px] text-[#6a6a6a] border-[1px] border-solid border-[#D2D2D2] cursor-pointer mt-0 mr-[10px] mb-[10px] ml-0;
          }
        }
      }
    }
  }
}

.resource-tile {
  @apply relative bg-[var(--color-grey)];

  .resource-thumbnail {
    aspect-ratio: 400/245;
    @apply w-full leading-[0];

    img {
      @apply w-full h-full object-cover;
    }
  }

  .resource-link {
    @apply absolute left-0 top-0 w-full h-full z-[9];
  }

  .content-wrapper {
    @apply pt-[40px] px-[30px] pb-[100px];

    .type {
      @apply text-right;
    }

    .bottom-content {
      @apply absolute bottom-[40px] pointer-events-none w-[calc(100%-60px)];
    }
  }

  /*.vertical {
    @apply before:content-[''] before:inline-block before:w-[10px] before:h-[10px] before:mr-[10px] before:align-middle before:rounded-[50%] before:mb-[2px] before:bg-sol-green;    

    &.symphonyai,
    &.ai {
      @apply before:bg-sol-orange;      
    }

    &.concertai {
      @apply before:bg-sol-green;      
    }

    &.financial-services {
      @apply before:bg-sol-royal-blue;      
    }

    &.retail-cpg {
      @apply before:bg-sol-green;      
    }

    &.industrial {
      @apply before:bg-sol-yellow;      
    }

    &.it-esm {
      @apply before:bg-sol-light-purple;      
    }

    &.business-it {
      @apply before:bg-sol-royal-blue;      
    }

    &.media {
      @apply before:bg-sol-pink;      
    }

    &.public-sector {
      @apply before:bg-sol-green;      
    }

    &.terarecon {      
      @apply before:bg-sol-green;      
    }

    &.financial-crime {
      @apply before:bg-sol-pink;      
    }

    &.itsm {
      @apply before:bg-sol-light-purple;      
    }
  }*/
}