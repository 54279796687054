.logo-container {
  @apply flex items-center justify-center;

  .logo-image {
    @apply h-[inherit] w-[inherit] #{!important};
    @apply flex-shrink-0;
  }
}

// Loop to create the responsive classes
@for $i from 1 through 10 {
  .mobile-cols-#{$i} {
    grid-template-columns: repeat(#{$i}, 1fr);
  }

  .tablet-cols-#{$i} {
    @include media(tablet) {
      grid-template-columns: repeat(#{$i}, 1fr);
    }
  }

  .desktop-cols-#{$i} {
    @include media(desktop) {
      grid-template-columns: repeat(#{$i}, 1fr);
    }
  }
}