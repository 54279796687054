:root {
    --color-blue: rgba(0, 116, 232, 1);
    --color-navy-blue: rgba(0, 64, 128, 1);
    --color-deep-purple: rgba(37, 1, 68, 1);
    --color-charcoal: rgba(37, 40, 46, 1);
    --color-grey: rgba(243, 249, 254, 1);
    --color-white: rgba(255, 255, 255, 1);
    --color-blue-50: rgba(0, 116, 232, 0.5);
    --color-purple-50: rgba(151, 45, 237, 0.5);
    --color-charcoal-75: rgba(37, 40, 46, 0.75);
    --color-charcoal-50: rgba(37, 40, 46, 0.5);
    --color-charcoal-15: rgba(37, 40, 46, 0.15);
    --color-text-selection: rgba(172, 206, 247, 1);
    --blue-gradient: linear-gradient(90deg, #0074E8 0%, #A933FB 100%);
}

$ColorPrimary: #0074E8;
$ColorDarkPrimary: #00558d;
$ColorDarkPrimary2: #005BB5;
$ColorDarkPrimary3: #004080;
$ColorSecondary: #250144;
$ColorLightBlue: #F3F9FE;
$ColorDefault: #25282E;
$ColorLightGray: #F5F5F5;
$ColorGray: #D9D9D9;
$ColorGray2: #DDD;
$ColorGray3: #EEE;
$ColorDarkGray: #17191B;
$ColorDarkGray2: #333;
$ColorBlack: #000;
$ColorWhite: #fff;
$ColorGreen: #00eac3;
$ColorYellow: #ffb600;
$ColorPink: #fb24a9;
$ColorOrange: #ff8500;
$ColorPurple: #a933fb;
$ColorLightPrimary: #80b9f3;
$ColorLightPurple: #b247fb;
$ColorRoyalBlue: #0074e8;
$ColorDarkPurple: #a933fb;
$blue-to-green-gradient: linear-gradient(120.94deg, $ColorPrimary 0.48%, $ColorGreen 95.81%);

$IconArrowRight: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 1L12 6L7 11' stroke='%231A2D39' stroke-width='2'/%3E%3Cpath d='M12 6L0 6' stroke='%231A2D39' stroke-width='2'/%3E%3C/svg%3E%0A");
$IconArrowCollapseWhite: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.85833 6.65574L11.6452 2.01157C11.9026 1.75506 12 1.4648 12 1.18804C12 1.01928 11.9652 0.864023 11.9026 0.729018C11.7495 0.378005 11.3808 -2.70672e-08 10.7824 -5.32221e-08L1.21572 -4.71396e-07C0.61737 -4.97551e-07 0.241662 0.378004 0.0955524 0.729017C-0.0575152 1.08003 -0.0644742 1.59981 0.359939 2.01157L5.14677 6.65574C5.61988 7.11475 6.39218 7.11475 6.86529 6.65574L6.85833 6.65574Z' fill='white'/%3E%3C/svg%3E");
$IconArrowCollapse: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.57222 4.43716L7.76344 1.34105C7.93506 1.17004 8 0.976534 8 0.792027C8 0.679523 7.97681 0.576015 7.93506 0.486012C7.83302 0.252003 7.58718 -1.80448e-08 7.18828 -3.54814e-08L0.810482 -3.14264e-07C0.41158 -3.317e-07 0.161108 0.252003 0.0637011 0.486012C-0.0383435 0.72002 -0.0429831 1.06654 0.239959 1.34105L3.43118 4.43716C3.74659 4.74317 4.26145 4.74317 4.57686 4.43716L4.57222 4.43716Z' fill='%231A2D39'/%3E%3C/svg%3E%0A");
$IconChevronUp: url("data:image/svg+xml,%3Csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7.5 7L14 1' stroke='%23164234' stroke-width='1.5'/%3E%3C/svg%3E");
$IconChevronUpWhite: url("data:image/svg+xml,%3Csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7.5 7L14 1' stroke='%23ffffff' stroke-width='1.5'/%3E%3C/svg%3E");
$IconChevronDownWhite:  url("data:image/svg+xml,%3Csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7L7.5 1L14 7' stroke='%23ffffff' stroke-width='1.5'/%3E%3C/svg%3E");
$IconPlus: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 0V21' stroke='%23164234' stroke-width='2'/%3E%3Cpath d='M21 10.9038L4.76837e-07 10.9038' stroke='%23164234' stroke-width='2'/%3E%3C/svg%3E");
$IconMinus: url("data:image/svg+xml,%3Csvg width='21' height='3' viewBox='0 0 21 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 1.90381L4.76837e-07 1.90381' stroke='%23164234' stroke-width='2'/%3E%3C/svg%3E%0A");

$IconPlusWhite: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M8 0V16' stroke='%23FFFFFF' stroke-width='2'/%3E%3Cpath d='M16 8L-7.15256e-07 8' stroke='%23FFFFFF' stroke-width='2'/%3E%3C/svg%3E");
$IconPlusDark: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M8 0V16' stroke='%2317191B' stroke-width='2'/%3E%3Cpath d='M16 8L-7.15256e-07 8' stroke='%2317191B' stroke-width='2'/%3E%3C/svg%3E");

$IconMinusWhite: url("data:image/svg+xml,%3Csvg width='21' height='3' viewBox='0 0 21 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 1.90381L4.76837e-07 1.90381' stroke='%23ffffff' stroke-width='2'/%3E%3C/svg%3E%0A");

$IconXWhite: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath d='M1.68628 1.34277L13 12.6565' stroke='%23FFFFFF' stroke-width='2'/%3E%3Cpath d='M13 1.34277L1.68629 12.6565' stroke='%23FFFFFF' stroke-width='2'/%3E%3C/svg%3E");
$IconXDark: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath d='M1.68628 1.34277L13 12.6565' stroke='%2317191B' stroke-width='2'/%3E%3Cpath d='M13 1.34277L1.68629 12.6565' stroke='%2317191B' stroke-width='2'/%3E%3C/svg%3E");
$base_url: "../img/";

.color {
    &-brand {
        &-white {
            @apply text-sol-white;
        }

        &-gray {
            &-darkest {
                @apply text-sol-charcoal;
            }
        }
    }
}