.ppw-post-password-container {
	.ppw-ppf-input-container {
		@apply relative pt-[45px] px-[40px] pb-[55px] before:content-[''] #{!important};

		&:before {
			background: linear-gradient(90deg, rgba(30, 172, 252, 1) 0%, rgba(0, 232, 196, 1) 100%);
			@apply absolute block top-0 left-0 w-full h-[4px] bg-[rgb(30,_172,_252)];
		}

		.ppw-ppf-desc h4 {
			@apply mb-[5px] text-[32px];
		}

		.ppw-ppf-field-container {
			@apply my-0 mx-auto w-full max-w-[400px];

			.ppw-pwd-label {
				@apply mb-0 w-full block text-transparent;

				label {
					@apply p-[12px];
				}

				input {
					@apply w-full block text-center border-[1px] border-solid border-[#cfc8d6] rounded-[5px] text-[14px] p-[7px];
				}
			}

			.ppw-pwd-submit-btn {
				@apply no-underline bg-sol-royal-blue uppercase mt-[10px] text-sol-white border-sol-royal-blue w-full border-none rounded-[24px] inline-block text-[14px] font-semibold tracking-[.32px] leading-normal pt-[13px] px-[28px] pb-[14px] relative text-center transition-all duration-[.25s] ease-out;
			}
		}
	}

	div.ppwp-wrong-pw-error {
		@apply relative pb-[40px];
	}
}