body.baunfire-homepage {
  .assessment-h1 {
    @apply text-[30px] leading-[1.2];

    @include media(tablet_up) {
      @apply text-[48px];
    }

    @include media(desktop) {
      @apply text-[60px];
    }

    @include media(full_width) {
      @apply text-[72px];
    }
  }

  .assessment-h2 {
    @apply min-[1440px]:text-[48px] md:text-[30px] text-[24px] leading-[1.2] m-0;
  }

  .assessment-h3 {
    @apply min-[1440px]:text-[30px] md:text-[24px] text-[21px] leading-[1.2] m-0;
  }

  .assessment-h4 {
    @apply min-[1440px]:text-[24px] md:text-[21px] text-[18px] leading-[1.5];
  }
}