.block-request-a-demo {
  @apply relative pt-[214px] px-0 md:pb-[80px] pb-[60px];

  .block-bg {
    @apply absolute top-0 left-0 bottom-0 right-0;

    img {
      @apply object-cover object-center w-full h-full;
    }
  }

  .form-wrapper {
    @apply bg-sol-white relative shadow-[0px_10px_30px_0px_rgba(0,0,0,0.1)] lg:p-[30px] py-[30px] px-[20px];
  }

  .title {
    @apply mb-[30px] text-[46px] leading-[55px];
  }

  .subtitle {
    @apply mb-[16px];
  }

  .form-wrapper__title {
    @apply mb-[30px];
  }

  .bottom-section-title .highlight {
    @apply bg-sol-light-blue2 inline-block ml-[-7px] py-0 px-[7px];
  }

  &.layout-dark {
    .block-bg {
      @apply bg-sol-charcoal;
    }

    .title,
    .subtitle,
    .rich-text:not(.confirmation)>* {
      @apply text-sol-white;
    }

    .rich-text:not(.confirmation) {
      ul li {
        @apply before:bg-sol-white;
      }
      ol li {
        @apply before:text-sol-white;
      }
    }
  }
}

.form-select-vertical {
  @apply relative w-full;

  .form-select-vertical__wrapper {
    background-color: rgba(243, 249, 254, 1);
    @apply block relative mt-0 mx-0 mb-[24px] h-[48px] rounded;

    select {
      @apply px-[16px];
    }
  }

  .form-select-vertical__wrapper {    
    &::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4IDlMMTIgMTVMNiA5IiBzdHJva2U9IiMyNTI4MkUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K");    
      @apply content-[''] absolute right-[16px] bg-repeat bg-contain w-[24px] h-[24px] top-[50%] translate-y-[-50%];
    }
  }
}

#select-vertical {
  @apply text-sol-charcoal appearance-none w-full h-full border-none bg-transparent outline-none text-[14px];
}

.confirmation-video iframe {
  @apply w-full h-full;  
}